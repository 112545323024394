angular
    .module('annexaApp')
    .component('annexaSelectDossierTransactionDocuments', {
        templateUrl: './components/common/annexa-select-dossier-transaction-documents/annexa-select-dossier-transaction-documents.html',
        controller: ['$rootScope', 'Language',function($rootScope, Language) {
            var vm = this;
			vm.languageColumn = Language.getActiveColumn();
			vm.selectedAll = false;
			
            if(!vm.title) {
                vm.title = 'global.literals.documents'
            }

            _.forEach(vm.documents, function (value, key) {
            	vm.documents[key].selected = ((vm.selectedAll)?true:false);
            	
            	if (vm.documentsIdSelected) {
            		var documentSelected = $linq(vm.documentsIdSelected).where("x => x == " + vm.documents[key].id).toArray();
            		if (documentSelected.length > 0) {
            			vm.documents[key].selected = true;
            		}
            	}
            });
			
            vm.selectDocument = function ($event, index) {
                var checkbox = $event.target;

                vm.documents[index].selected = checkbox.checked;
				if(!checkbox.checked){
					vm.selectedAll = false;
				}else{
					vm.selectedAll = (($linq(vm.documents).count("x => x.selected") == vm.documents.length)?true:false);
				}
				$rootScope.$broadcast('annexa-select-dossier-transaction-documents-selectedDocuments', {});
            }

			vm.selectAll = function(){
				_.forEach(vm.documents, function (value, key) {
            		vm.documents[key].selected = ((vm.selectedAll)?true:false);
            	});
				$rootScope.$broadcast('annexa-select-dossier-transaction-documents-selectedDocuments', {});
			}
			this.$onInit = function() {
				$rootScope.$broadcast('annexa-select-dossier-transaction-documents-selectedDocuments', {});
				vm.selectedAll = (($linq(vm.documents).count("x => x.selected") == vm.documents.length)?true:false);
				vm.descriptionLanguage = "descriptionLanguage" + vm.languageColumn.substr(vm.languageColumn.length - 1);
            }
        }],
        bindings: {
            title: '@?',
            documents: '=',
            documentsIdSelected: '='
        }
    })