angular
    .module('annexaApp')
    .factory('annexaDashboardFactory',['$q', '$http', 'GlobalDataFactory', '$filter', 'Language', '$rootScope','HelperService', 'TableFilterFactory', 'RestService', 'SecFactory', 'apiAdmin', 'NotificationFactory','TaskFactory', 'RemittanceFactory', function($q, $http, GlobalDataFactory, $filter, Language, $rootScope, HelperService, TableFilterFactory, RestService, SecFactory, apiAdmin, NotificationFactory, TaskFactory, RemittanceFactory) {
        var factory = {};

        factory.documentCounters = [];
        factory.tramCounters = [];
        factory.regCounters = [];
		factory.regOutCounters = [];
        factory.taskFullCounters = [];
        factory.taskSimpleCounters = [];
        factory.archiveCounters = [];
        factory.secCounters = [];

        factory.loadDashboard = function() {
        	var deferrend = $q.defer();
        	factory.documentCounters = [];
            factory.tramCounters = [];
            factory.regCounters = [];
            factory.regOutCounters = [];
        	factory.taskFullCounters = [];
            factory.taskSimpleCounters = [];
            factory.archiveCounters = [];
            factory.secCounters = [];
			var dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'DOC'").toArray();
			}
			var docStatuses = $linq(GlobalDataFactory.documentStatuses).where("x => x.id != ''").orderBy("x => x.orderStatus").toArray();
            _.forEach(docStatuses, function(item) {
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == '"+item.id+"'"))){
					var dsTrobat = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.stateParams && x.stateParams.status == '"+item.id+"'");
	            	if(!dsTrobat && !item.hide){
	                    factory.documentCounters.push({
	                        idState: item.id,
		                    state: 'annexa.doc.documents.all',
	                        stateParams: { status: item.id },
	                        icon: item.icon,
	                        style: item.style,
	                        title: 'global.documentState.' + item.id,
	                        number: ''
	                    });
			           	if(item.id === 'PENDING_SIGN'){
							$http({ method: 'GET', url: './api/doc/PENDING_SIGN/status/count' }).then(function(data) {
								var itemAdded = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"'");
								if(itemAdded){
		            				itemAdded.number = (data.data && data.data > 0 ? data.data : '');
								}
				            }).catch(function(error) {
				            	
				            });
						}
	            	}
				}
            })
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+item.id);
						var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id,
		                        state: ((item.filter.state)?item.filter.state:((item.filter.tableName != "Document")?'annexa.doc.documents.type':'annexa.doc.documents.all')),
								stateParams: {filterId: item.filter.id},
		                        title: item.filter.name,
		                        number: ''
		                    };
							if(item.filter.tableName != "Document"){
								itemAdded.stateParams.type = item.filter.tableName.substring("Document".length, item.filter.tableName.length);
							}
			                factory.documentCounters.push(itemAdded);
							if(itemAdded.state === 'annexa.doc.documents.type'){
								var documentsByType = $rootScope.app.configuration.documents_by_type;
								if(documentsByType.active == 'true') {
									var selectedType = $linq(documentsByType.docTypes).singleOrDefault(undefined, "x => x.type.id == " + itemAdded.stateParams.type);
						            if(selectedType) {
										var filterType = {
					                        type: selectedType.type.id,
					                        filter: []
					                    };
										var normalFilterColumns = [
								            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
								            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
								            { id: 'template', type: 'select', order: 2, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + Language.getActiveColumn()).toArray(), addAll: true, nameProperty: 'description' },
								            { id: 'section', type: 'select-tree', order: 3, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
								            { id: 'type', type: 'select', order: 4, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: Language.getActiveColumn() },
								            { id: 'origin', type: 'select', order: 5, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
								            { id: 'createUser', type: 'text', order: 6, label: 'global.literals.user', callAux: true },
								            { id: 'meta', type: 'text', order: 7, label: 'global.literals.meta', callAux: true },
								            { id: 'modifyDdate', type: 'dateRange', order: 8, label: 'global.literals.modified', callAux: true },
								            { id: 'csv', type: 'text', order: 9, label: 'global.documents.new.csv', callAux: true },
								            { id: 'dossierNumber', type: 'text', order: 10, label: 'global.literals.dossier', callAux: true },
								            { id: 'digitalized', type: 'checkbox', order: 10, label: 'global.documents.onlyScanned', callAux: true},
								            { id: 'internalDoc', type: 'checkbox', order: 11, label: 'global.documents.internalDocument', callAux: true},
											{ id: 'publish', type: 'select', order: 12, label: 'global.documents.onlyPublishedDocumentsIn', dataType: 'LOCAL', data: GlobalDataFactory.publishList, addAll: true, nameProperty: 'name', callAux: true},
											{ id: 'archiveClassification', type: 'select-tree', order: 13, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn() },
											{ id: 'expirationDdate', type: 'dateRange', order: 15, label: 'global.literals.expirationDate', callAux: true },
											{ id: 'rejectedSign', type: 'checkbox', order: 17, label: 'global.literals.rejectedSign', callAux: true}
								        ];
										var order = 0;
										_.forEach(selectedType.filterColumns, function (column) {
					                        if (column.type == 'NORMAL') {
					                            var normalColumn = $linq(normalFilterColumns).singleOrDefault(undefined, "x => x.id == '" + column.id + "'");
												if (normalColumn) {
						                            if(selectedType.predefinedFilters){
														var predefinedFilter = $linq(selectedType.predefinedFilters).firstOrDefault(undefined,"x => x.name == '"+column.id+"'");
														if(predefinedFilter && predefinedFilter.value){
															normalColumn.preFilter = HelperService.getPrefilterType(normalColumn, predefinedFilter, 'id');
														}
														if(predefinedFilter && predefinedFilter.negated){
															normalColumn.negated = HelperService.getPrefilterNegatedType(normalColumn,predefinedFilter,'id'); 
														}
													}
												    normalColumn.order = order;
					                                filterType.filter.push(normalColumn);
					                            }
					                        } else {
					                            var customColumn = $linq(GlobalDataFactory.customFields).singleOrDefault(undefined, "x => x.id == " + column.id);
					                            if (customColumn) {
													var customFilterCreated = TableFilterFactory.getCustomFieldFilter(customColumn, order);
													var predefinedFilter = $linq(selectedType.predefinedFilters).firstOrDefault(undefined,"x => x.name == '"+column.id+"'");
													if(predefinedFilter && predefinedFilter.value){
														customFilterCreated.preFilter = HelperService.getPrefilterType(customFilterCreated, predefinedFilter, 'id');
													}	
													if(predefinedFilter && predefinedFilter.negated){
															customFilterCreated.negated = HelperService.getPrefilterNegatedType(customFilterCreated,predefinedFilter,'id'); 
														}
					                                filterType.filter.push(customFilterCreated);
					                            }
					                        }
					                        order++;
					                    });
										if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
											HelperService.getFilterByFilterId(filterType.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
											var filterCall = TableFilterFactory.getFilterCall(filterType.filter);
					                        filterCall.type = { id: itemAdded.stateParams.type };
								            var filterCallAux = TableFilterFactory.getFilterCallAux(filterType.filter);
											filterCallAux.loggedUser = $rootScope.LoggedUser.id;
											filterCallAux.documentsByTypeActive = itemAdded.stateParams.type;	
											return RestService.countersFilterDashboard(item.id+'', 'doc', 'Document', filterCall, filterCallAux)
				                                .then(function (data) {
				                                    var itemAdded = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
													if(itemAdded){
							            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
													}
				                                });
										}		
						            }
								}					
							}else{
								var filter = [
						            { id: 'code', type: 'text', order: 0, label: 'global.literals.code', preFilter: HelperService.getPrefilter('documents','tableDocs','code'), negated: HelperService.getPrefilterNegated('documents','tableDocs','code')},
						            { id: 'name', type: 'text', order: 1, label: 'global.literals.title', preFilter: HelperService.getPrefilter('documents','tableDocs','name'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','name')},
						            { id: 'template', type: 'select', order: 2, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + Language.getActiveColumn()).toArray(), addAll: true, nameProperty: 'description', preFilter: HelperService.getPrefilter('documents','tableDocs','template',GlobalDataFactory.templates,'id'), negated: HelperService.getPrefilterNegated('documents','tableDocs','template')  },
						            { id: 'section', type: 'select-tree', order: 3, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('documents','tableDocs','section',GlobalDataFactory.sections,'id'), negated: HelperService.getPrefilterNegated('documents','tableDocs','section') },
						            { id: 'type', type: 'select', order: 4, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('documents','tableDocs','type',GlobalDataFactory.documentTypes,'id') , negated:  HelperService.getPrefilterNegated('documents','tableDocs','type')},
						            { id: 'origin', type: 'select', order: 5, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('documents','tableDocs','origin',GlobalDataFactory.docModules,'id') , negated: HelperService.getPrefilterNegated('documents','tableDocs','origin')},
						            { id: 'createUser', type: 'text', order: 6, label: 'global.literals.user', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','createUser'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','createUser')},
						            { id: 'meta', type: 'text', order: 7, label: 'global.literals.meta', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','meta'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','meta')},
						            { id: 'modifyDdate', type: 'dateRange', order: 8, label: 'global.literals.modified', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','modifyDdate'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','modifyDdate')},
						            { id: 'csv', type: 'text', order: 9, label: 'global.documents.new.csv', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','csv'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','csv')},
						            { id: 'dossierNumber', type: 'text', order: 10, label: 'global.literals.dossier', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','dossierNumber'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','dossierNumber') },
						            { id: 'digitalized', type: 'checkbox', order: 11, label: 'global.documents.onlyScanned', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','digitalized')},
						            { id: 'internalDoc', type: 'checkbox', order: 12, label: 'global.documents.internalDocument', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','internalDoc')},
									{ id: 'publish', type: 'select', order: 13, label: 'global.documents.onlyPublishedDocumentsIn', dataType: 'LOCAL', data: GlobalDataFactory.publishList, addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('documents','tableDocs','publish',GlobalDataFactory.publishList,'id') , negated:HelperService.getPrefilterNegated('documents','tableDocs','publish') },
									{ id: 'archiveClassification', type: 'select-tree', order: 14, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('documents','tableDocs','archiveClassification',GlobalDataFactory.archiveClassifications,'id') , negated: HelperService.getPrefilterNegated('documents','tableDocs','archiveClassification') },
									{ id: 'expirationDdate', type: 'dateRange', order: 15, label: 'global.literals.expirationDate', callAux: true, preFilter: HelperService.getPrefilter('documents','tableDocs','expirationDdate'), negated:  HelperService.getPrefilterNegated('documents','tableDocs','expirationDdate') },
									{ id: 'related', type: 'checkbox', order: 16, label: 'global.literals.showRelateds', callAux: true, preFilter:false, negated:false},
									{ id: 'rejectedSign', type: 'checkbox', order: 17, label: 'global.literals.rejectedSign', callAux: true, preFilter:false, negated:false}
									
						        ]
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
						            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
        							filterCallAux.langColumn = Language.getActiveColumn();
									return RestService.countersFilterDashboard(item.id+'', 'doc', 'Document', filterCall, filterCallAux, 'documents')
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.documentCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });
								}					
							}
						}		
					}
	            })
			}
			dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'TRAM'").toArray();
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'tramPendingTramitation'"))){
	            var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramPendingTramitation'");
	           
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'tramPendingTramitation',
		                state: 'annexa.tram.pending',
	                    stateParams: undefined,
	                    title: 'global.literals.tramPendingTramitation',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/PendingTramitation' }).then(function(data) {
           				var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramPendingTramitation'");
						if(itemAdded){
            				itemAdded.number = ((data.data && data.data.hasOwnProperty("PendingTramitation") && data.data.PendingTramitation > 0 )? data.data.PendingTramitation : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'outOfTime'"))){
		        var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'outOfTime',
		                state: 'annexa.tram.pending',
	                    stateParams: { type: 'expired' },
	                    title: 'global.literals.outOfTime',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/Expired' }).then(function(data) {
						var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
						if(itemAdded){
							itemAdded.number = ((data.data && data.data.hasOwnProperty("Expired") && data.data.Expired > 0 )? data.data.Expired : '');
						}		            	
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'tramFavorite'"))){
	        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramFavorite'");
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'tramFavorite',
		                state: 'annexa.tram.pending',
	                    stateParams: { type: 'favorit' },
	                    title: 'global.literals.tramFavorite',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/Favorite' }).then(function(data) {
		            	var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramFavorite'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("Favorite") && data.data.Favorite > 0 )? data.data.Favorite : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'tramPendingSee'"))){
	        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramPendingSee'");
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'tramPendingSee',
		                state: 'annexa.tram.view',
	                    stateParams: { type: 'tramitation' },
	                    title: 'global.literals.tramPendingSee',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/PendingSee' }).then(function(data) {
						var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramPendingSee'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("PendingSee") && data.data.PendingSee > 0 )? data.data.PendingSee : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'tramFinally'"))){
	        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramFinally'");
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'tramFinally',
		                state: 'annexa.tram.view',
	                    stateParams: { type: 'finally' },
	                    title: 'global.literals.tramFinally',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/Finally' }).then(function(data) {
						var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'tramFinally'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("Finally") && data.data.Finally > 0 )? data.data.Finally : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'dossierRightAccess'"))){
	        	var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'dossierRightAccess'");
	        	if(!dsTrobat){
	                factory.tramCounters.push({
	                    idState: 'dossierRightAccess',
		                state: 'annexa.tram.dossierRightAccess',
	                    title: 'global.tram.tabs.dossierRightAccess.title',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/tram/counters/RightAccess' }).then(function(data) {
						var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == 'dossierRightAccess'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("RightAccess") && data.data.RightAccess > 0 )? data.data.RightAccess : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+item.id);
						 var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id,
		                        state: ((item.filter.state)?item.filter.state:((item.filter.tableName === 'Dossier')?'annexa.tram.view':'annexa.tram.pending')),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
		                        number: ''
		                    };
							if(!item.filter.state || item.filter.state.startsWith("annexa.tram")){
			                	factory.tramCounters.push(itemAdded);
								if(itemAdded.state === 'annexa.tram.view'){
									var filter = [
							            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierNumber')},
							            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','family')},
							            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedure')},
							            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: HelperService.getPrefilter('tramitation','tableDossierView','procedureClassification', GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','procedureClassification')},
							            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','subject')},
							            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','thirds')},
							            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','transaction')},
							            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','transactionType', GlobalDataFactory.transactiontypes, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','transactionType')},
							            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','custom_fields_search')},
							            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dates')},
							            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierResponsibleUser')},
							            (
							                ($rootScope.esetMode)?
							                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleUser')}
							                    :
							                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','creator')}
							            ),
							            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','register_entry')},
							            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true , preFilter:HelperService.getPrefilter('tramitation','tableDossierView','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','dossierStatus')},
							            { id: 'responsibleDossierProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsiblesDossierProfiles', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','responsibleDossierProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','responsibleDossierProfile')},
							            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','address') },
										{ id: 'show_archived', type: 'checkbox', order: 13, label: 'global.archive.archiveds', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','show_archived')},
										{ id: 'archiveClassification', type: 'select-tree', order: 14, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','archiveClassification')},
										{ id: 'dossierClosed', type: 'checkbox', order: 15, label: 'global.literals.showClosed', callAux: true, preFilter:false, negated:false},
										{ id: 'externalCode', type: 'text', order: 16, label: 'global.literals.externalCode', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','externalCode'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','externalCode')}
									];
							        if($rootScope.app.configuration.tram_classification_dossier.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 15, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierView','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierView','tramClassification')});
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.filterOrigin = 'dossierView';
            							filterCallAux.loggedUser = $rootScope.LoggedUser.id;
							            filterCallAux.langColumn = Language.getActiveColumn();
							            return RestService.countersFilterDashboard(item.id+'', 'tram', 'Dossier', filterCall, filterCallAux, 'annexa-gee-local')
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}	
								}else if(itemAdded.state === 'annexa.tram.responsable'){
									var dossierStatuses = [
							            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
							            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
							            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') }
							        ];
							        var tramitationEstates = [
							            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
							            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
							        ];
							        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
							        $linq(allProfilesBasic).foreach(function (x) { 
							        	delete x['userProfiles'];
							        	delete x['ficticio'];
							        });
							       var filter = [
							            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dossierNumber')},
							            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','family')},
							            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedure', GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedure')},
							            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','procedureClassification', GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','procedureClassification')},
							            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','subject')},
							            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','thirds')},
							            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','transaction')},
							            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','transactionType', GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','transactionType')},
							            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','custom_fields_search')},
							            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','dates') , negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','dates')},
							            (
							                ($rootScope.esetMode)?
							                { id: 'responsibleUser', type: 'text', order: 8, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUser')}
							                    :
							                { id: 'creator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','creator')}
							            ),
							            { id: 'register_entry', type: 'text', order: 9, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','register_entry')},
							            { id: 'responsibleUserTram', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleUserTram'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleUserTram')},
							            { id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','responsibleTramitProfile')},
							            { id: 'address', type: 'text', order: 11, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','address') },
							            { id: 'archiveClassification', type: 'select-tree', order: 12, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','archiveClassification')},      
							            { id: 'dossierClosed', type: 'checkbox', order: 15, label: 'global.literals.showClosed', callAux: true, preFilter:false, negated:false},
										{ id: 'externalCode', type: 'text', order: 16, label: 'global.literals.externalCode', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','externalCode'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','externalCode')}      
							        ];
							        if($rootScope.app.configuration.tram_classification_dossier.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 13, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','tramClassification')});
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.filterOrigin = 'dossierView';
            							filterCallAux.loggedUser = $rootScope.LoggedUser.id;
							            filterCallAux.langColumn = Language.getActiveColumn();
				                        filterCallAux.responsibleFilter = true;
							            filterCallAux.dossierStatus = {id:"IN_PROGRESS"};
							            return RestService.countersFilterDashboard(item.id+'', 'tram', 'DossierTransaction', filterCall, filterCallAux, 'annexa-gee-local')
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}
								}else if(itemAdded.state === 'annexa.tram.dossierRightAccess'){
									var dossierStatuses = [
							            { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
							            { id: 'FINISHED', name: $filter('translate')('FINISHED') },
							            { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
							            { id: 'CLOSED', name: $filter('translate')('CLOSED') },
							            { id: 'TRANSFER', name: $filter('translate')('TRANSFER') },
							            { id: 'REJECTED', name: $filter('translate')('REJECTED') },
							            { id: 'ARCHIVED', name: $filter('translate')('ARCHIVED') }
							        ];
							        var tramitationEstates = [
							            { id: 'TRAMITATION', name: $filter('translate')('global.literals.tramParticipated') },
							            { id: 'SEE', name: $filter('translate')('global.literals.tramPendingSee') }
							        ];
							        var filter = [
							            { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierNumber')},
							            { id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','family')},
							            { id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedure')},
							            { id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','procedureClassification',GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','procedureClassification')},
							            { id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','subject')},
							            { id: 'thirds', type: 'text', order: 3, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','thirds')},
							            { id: 'transaction', type: 'text', order: 5, label: 'global.literals.transaction', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','transaction'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','transaction')},
							            { id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','transactionType',GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','transactionType')},
							            { id: 'custom_fields_search', type: 'text', order: 6, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','custom_fields_search')},
							            { id: 'dates', type: 'dateRange', order: 7, label: 'global.delegate.startDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dates') },
							            { id: 'dossierResponsibleUser', type: 'text', order: 8, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierResponsibleUser')},
							            (
							                ($rootScope.esetMode)?
							                { id: 'responsibleUser', type: 'text', order: 9, label: 'global.literals.responsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','responsibleUser')}
							                    :
							                { id: 'creator', type: 'text', order: 9, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','creator')}
							            ),
							            { id: 'register_entry', type: 'text', order: 10, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','register_entry')},
							            { id: 'dossierStatus', type: 'select', order: 11, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy(dossierStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','dossierStatus',dossierStatuses,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','dossierStatus') },
							            { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','address') },
							            { id: 'externalCode', type: 'text', order: 13, label: 'global.literals.externalCode', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierRightAccess','externalCode'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierRightAccess','externalCode')},
							        ];
							        
							        if($rootScope.app.configuration.tram_classification_dossier.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 14, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierResponsible','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierResponsible','tramClassification')});
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.filterOrigin = 'dossierRightAccess';
							            filterCallAux.langColumn = Language.getActiveColumn();
							            filterCallAux.loggedUser = $rootScope.LoggedUser.id;
										return RestService.countersFilterDashboard(item.id+'', 'tram', 'Dossier', filterCall, filterCallAux, 'dossierRightAccess')
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}		
								}else{
									var expiredStates = [
							            { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
							            { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
							        ];
							        var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
							        $linq(allProfilesBasic).foreach(function (x) { 
							        	delete x['userProfiles'];
							        	delete x['ficticio'];
							        });
									var filter = [];
							        filter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierNumber')});
							        filter.push({ id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','family',GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','family')});
							        filter.push({ id: 'procedure', type: 'select', order: 2, label: 'global.literals.procedure', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedure')});
							        filter.push({ id: 'procedureClassification', type: 'select', order: 3, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.procedureClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','procedureClassification',GlobalDataFactory.procedureClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','procedureClassification')});
							        filter.push({ id: 'subject', type: 'text', order: 4, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','subject')});
							        filter.push({ id: 'thirds', type: 'text', order: 5, label: 'global.literals.thirdsInt', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','thirds'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','thirds')});
							        filter.push({ id: Language.getActiveColumn(), type: 'text', order: 6, label: 'global.literals.transaction', preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','language'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','language')});
							        filter.push({ id: 'transactionType', type: 'select', order: 6, label: 'global.literals.transactionType', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','transactionType',GlobalDataFactory.transactiontypes,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','transactionType')});
							        filter.push({ id: 'custom_fields_search', type: 'text', order: 7, label: 'global.literals.customFields', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','custom_fields_search'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','custom_fields_search')});
							        filter.push({ id: 'dates', type: 'dateRange', order: 8, label: 'global.tram.literals.startDateTram', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dates'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dates')});
							        filter.push({ id: 'creator', type: 'text', order: 9, label: 'global.tram.literals.tramCreator', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','creator'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','creator')});
							        filter.push({ id: 'dossierResponsibleUser', type: 'text', order: 10, label: 'global.tram.literals.dossierResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','dossierResponsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','dossierResponsibleUser')});
							        if ($rootScope.esetMode) {
							        	filter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, model:(($rootScope.LoggedUser)?($rootScope.LoggedUser.name+" "+$rootScope.LoggedUser.surename1+(($rootScope.LoggedUser.surename2)?' '+$rootScope.LoggedUser.surename2:'')):undefined), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
							        } else {        	
							        	filter.push({ id: 'responsibleUser', type: 'text', order: 10, label: 'global.tram.literals.tramResponsible', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleUser'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleUser')});
							        }
							        filter.push({ id: 'responsibleTramitProfile', type: 'select-multiple', order: 11, label: 'global.literals.responsibleTramitProfile', dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','responsibleTramitProfile',allProfilesBasic,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','responsibleTramitProfile')});
							        filter.push({ id: 'register_entry', type: 'text', order: 12, label: 'global.literals.register_entry_INI', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','register_entry'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','register_entry')});
							        filter.push({ id: 'expired', type: 'select', order: 13, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','expired')});
							        filter.push({ id: 'address', type: 'text', order: 14, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','address'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','address')});
							        filter.push({ id: 'tramitationPhase', type: 'select-multiple', order: 15, label: 'global.literals.tramitationPhase', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.tramitationPhase,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','tramitationPhase', GlobalDataFactory.tramitationPhase, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','tramitationPhase')});
							        filter.push({ id: 'archiveClassification', type: 'select-tree', order: 15, label: 'global.literals.classificationBox', dataType: 'LOCAL', data: GlobalDataFactory.archiveClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','archiveClassification', GlobalDataFactory.familyProcedures,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','archiveClassification') });
							        if($rootScope.app.configuration.tram_classification_dossier.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 16, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','tramClassification')});
							        }
						        	filter.push({ id: 'organ', type: 'select', order: 17, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: GlobalDataFactory.organs, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','organ',GlobalDataFactory.organs,'id'), negated:HelperService.getPrefilter('tramitation','tableDossierTransactionView','organ',GlobalDataFactory.organs,'id')});
						        	filter.push({ id: 'externalCode', type: 'text', order: 18, label: 'global.literals.externalCode', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableDossierTransactionView','externalCode'), negated:HelperService.getPrefilterNegated('tramitation','tableDossierTransactionView','externalCode')});
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										filterCall.actual = true;
            							filterCall.dossier = {closed:false};
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
							            filterCallAux.langColumn = Language.getActiveColumn();
							            if(filterCallAux.expired){
							                if(filterCallAux.expired.id == 'expired'){
							                    filterCallAux.expired = true;
							                }else if(filterCallAux.expired.id == 'noExpired'){
							                    filterCallAux.expired = false;
							                }else{
							                    filterCallAux.expired = undefined;
							                }
							            }
							            filterCallAux.query = false;
										return RestService.countersFilterDashboard(item.id+'', 'tram', 'DossierTransaction', filterCall, filterCallAux, 'annexa-gee-local')
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.tramCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}			
								}
							}
						}		
					}
	            })
			}
			
			dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'REG_INPUT'").toArray();
			}
			var diligenceTypes = GlobalDataFactory.registerDiligenceTypes;
            _.forEach(diligenceTypes, function(diligenceType) {
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == '"+diligenceType.id+"'"))){
		      		var titleDilig = $filter('translate')('global.literals.dashboardDiligenceType', { diligenceType: diligenceType[Language.getActiveColumn()]});
	            	var dsTrobat = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+diligenceType.id);
	            	if(!dsTrobat){
	            		factory.regCounters.push({
	                        idState: diligenceType.id,
		                    permissions: ['view_input_register', 'diligence_input_register','view_all_diligences','view_all_input_register'],
	                        state: 'annexa.reg.input.diligences',
	                        stateParams: { diligenceType: diligenceType.id, diligenceStatus: ((diligenceType.canAppendDossier || diligenceType.canStartDossier)?'PENDING_ACCEPT':'PENDING_RECEPT') },
	                        title: titleDilig,
	                        number: ''
	                    });
						$http({ method: 'GET', url: './api/reg/dashboard/counters/'+diligenceType.id}).then(function(data) {
			            	var itemAdded = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState == "+diligenceType.id);
							if(itemAdded){
		           				itemAdded.number = ((data.data && data.data.hasOwnProperty("DiligenceType_"+diligenceType.id) && data.data["DiligenceType_"+diligenceType.id] > 0 )? data.data["DiligenceType_"+diligenceType.id] : '');
							}
			            }).catch(function(error) {
			            	
			            });
	            	}
				}
            })
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState && x.idState === '"+item.id+"Filter'");
						 var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id+'Filter',
		                        state: ((item.filter.state)?item.filter.state:((item.filter.tableName === 'RegisterEntry')?'annexa.reg.input.entries':'annexa.reg.input.diligences')),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
		                        number: ''
		                    };
							if(!item.filter.state || item.filter.state.startsWith("annexa.reg.input")){
			                	factory.regCounters.push(itemAdded);
								if(itemAdded.state === 'annexa.reg.input.diligences'){
									var adiElements = [
							            { id: -1, language1: $filter('translate')('global.literals.withoutDiligences'), language2: $filter('translate')('global.literals.withoutDiligences'), language3: $filter('translate')('global.literals.withoutDiligences') }
							        ];
									var registerEntryDiligenceStates = [
							            { id: 'ACCEPT', name: 'global.reg.diligenceState.ACCEPT' },
							            { id: 'REJECT', name: 'global.literals.REJECT' },
							            { id: 'READ', name: 'global.reg.diligenceState.READ' },
							            { id: 'PENDING_ACCEPT', name: 'global.reg.diligenceState.PENDING_ACCEPT' },
							            { id: 'PENDING_RECEPT', name: 'global.reg.diligenceState.PENDING_RECEPT' },
							            { id: 'FINISHED', name: 'global.reg.diligenceState.FINISHED' }
							        ];
									var filter = [
							            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number',preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryNumber') },
							            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','procedureName'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','procedureName') },
							            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dossierNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dossierNumber') },
							            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryChannel',GlobalDataFactory.allRegisterEntryChannelsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryChannel')  },
							            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','extract'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','extract')},
							            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataReg'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataReg') },
							            { id: 'dataPres', type: 'dateRange', order: 7, label: 'global.literals.presFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataPres'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataPres') },
							            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryDocumentType',GlobalDataFactory.registerEntryDocumentTypesInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryDocumentType') },
							            { id: 'entryClassification', type: 'select', order: 9, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryClassification',GlobalDataFactory.registerEntryClassificationsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryClassification') },
							            { id: 'representant', type: 'text', order: 11, label: 'global.literals.origin', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','representant'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','representant') },
							            { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','section',GlobalDataFactory.sections,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','section') },
							            { id: 'diligenceProfile', type: 'select-multiple', order: 14, label: 'global.literals.diligenceprofile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','diligenceProfile',GlobalDataFactory.allProfiles,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','diligenceProfile') },
							            { id: 'originEntryNumber', type: 'text', order: 15, label: 'global.literals.originEntryInputNumber', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','originEntryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','originEntryNumber') },
							            { id: 'originRegisterOrgan', type: 'text', order: 17, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableRegInputEntries','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','originRegisterOrgan') },
										{ id: 'diligenceType', type: 'select', order:  10, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: GlobalDataFactory.registerDiligenceTypes, addAll: true, addItems: angular.copy(adiElements),nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','diligenceType',GlobalDataFactory.registerDiligenceTypes,'id'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','diligenceType') },
										{ id: 'diligenceStatus', type: 'select-multiple', order: 14, label: 'global.literals.state', dataType: 'LOCAL', data: registerEntryDiligenceStates, addAll: true, nameProperty:'name', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputDiligence','diligenceStatus', registerEntryDiligenceStates, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegInputDiligence','diligenceStatus') },
										{ id: 'responsibleUser', type: 'text', order: 15, label: 'global.literals.responsibleUser', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputDiligence','responsibleUser'), negated:HelperService.getPrefilterNegated('register','tableRegInputDiligence','responsibleUser') },
										{ id: 'withoutDossier', type: 'checkbox', order: 16, label: 'global.literals.withoutDossier', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputDiligence','withoutDossier'), negated:HelperService.getPrefilterNegated('register','tableRegInputDiligence','withoutDossier')}
							        ]
							        if($rootScope.app.configuration.tram_classification_register_entry.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 17, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableRegInputEntries', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableRegInputEntries', 'tramClassification')})
							        }
							        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
							            filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryOffice',GlobalDataFactory.registerEntryOffices,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryOffice') });
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										filterCall.entryType = 'INPUT';
										filterCall = { registerEntry: filterCall };
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
	        							filterCallAux.langColumn = Language.getActiveColumn();
					            		filterCallAux.diligenceWindow = true;
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'RegisterEntryDiligenceView', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}									
								}else if(itemAdded.state === 'annexa.reg.input.diligences.rejected'){
									var filter = [
							            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number',preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryNumber') },
							            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','procedureName'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','procedureName') },
							            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dossierNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dossierNumber') },
							            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryChannel',GlobalDataFactory.allRegisterEntryChannelsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryChannel')  },
							            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','extract'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','extract')},
							            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataReg'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataReg') },
							            { id: 'dataPres', type: 'dateRange', order: 7, label: 'global.literals.presFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataPres'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataPres') },
							            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryDocumentType',GlobalDataFactory.registerEntryDocumentTypesInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryDocumentType') },
							            { id: 'entryClassification', type: 'select', order: 9, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryClassification',GlobalDataFactory.registerEntryClassificationsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryClassification') },
							            { id: 'representant', type: 'text', order: 11, label: 'global.literals.origin', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','representant'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','representant') },
							            { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','section',GlobalDataFactory.sections,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','section') },
							            { id: 'originEntryNumber', type: 'text', order: 14, label: 'global.literals.originEntryInputNumber', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','originEntryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','originEntryNumber') },
							            { id: 'originRegisterOrgan', type: 'text', order: 13, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableRegInputEntries','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','originRegisterOrgan') },
							            { id: 'diligenceType', type: 'select', order:  10, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: GlobalDataFactory.registerDiligenceTypes, addAll: true, addItems: angular.copy(adiElements),nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','diligenceType',GlobalDataFactory.registerDiligenceTypes,'id'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','diligenceType') },
										{ id: 'responsibleUser', type: 'text', order: 15, label: 'global.literals.responsibleUser', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputDiligence','responsibleUser'), negated:HelperService.getPrefilterNegated('register','tableRegInputDiligence','responsibleUser') },
										{ id: 'withoutDossier', type: 'checkbox', order: 16, label: 'global.literals.withoutDossier', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputDiligence','withoutDossier'), negated:HelperService.getPrefilterNegated('register','tableRegInputDiligence','withoutDossier')}
							        ]
							        if($rootScope.app.configuration.tram_classification_register_entry.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 17, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableRegInputEntries', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableRegInputEntries', 'tramClassification')})
							        }
							        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
							            filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryOffice',GlobalDataFactory.registerEntryOffices,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryOffice') });
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										filterCall.entryType = 'INPUT';
										filterCall = { registerEntry: filterCall };
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
	        							filterCallAux.langColumn = Language.getActiveColumn();
										filterCallAux.tabType='RegInputDiligencesRejected';
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'RegisterEntryDiligenceView', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}
								}else{
									var adiElements = [
							            { id: -1, language1: $filter('translate')('global.literals.withoutDiligences'), language2: $filter('translate')('global.literals.withoutDiligences'), language3: $filter('translate')('global.literals.withoutDiligences') }
							        ];
									var filter = [
							            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number',preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryNumber') },
							            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','procedureName'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','procedureName') },
							            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dossierNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dossierNumber') },
							            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryChannel',GlobalDataFactory.allRegisterEntryChannelsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryChannel')  },
							            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','extract'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','extract')},
							            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataReg'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataReg') },
							            { id: 'dataPres', type: 'dateRange', order: 7, label: 'global.literals.presFrom', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','dataPres'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','dataPres') },
							            { id: 'entryDocumentType', type: 'select', order: 8, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryDocumentType',GlobalDataFactory.registerEntryDocumentTypesInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryDocumentType') },
							            { id: 'entryClassification', type: 'select', order: 9, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','entryClassification',GlobalDataFactory.registerEntryClassificationsInput,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','entryClassification') },
							            { id: 'representant', type: 'text', order: 11, label: 'global.literals.origin', callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','representant'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','representant') },
							            { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','section',GlobalDataFactory.sections,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','section') },
							            { id: 'diligenceProfile', type: 'select-multiple', order: 14, label: 'global.literals.diligenceprofile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,Language.getActiveColumn()).sort(), addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: HelperService.getPrefilter('register','tableRegInputEntries','diligenceProfile',GlobalDataFactory.allProfiles,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','diligenceProfile') },
							            { id: 'originEntryNumber', type: 'text', order: 15, label: 'global.literals.originEntryInputNumber', preFilter: HelperService.getPrefilter('register','tableRegInputEntries','originEntryNumber'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','originEntryNumber') },
										{ id: 'diligenceType', type: 'select', order:  10, label: 'global.literals.diligenceType', dataType: 'LOCAL', data: GlobalDataFactory.registerDiligenceTypes, addAll: true, addItems: angular.copy(adiElements),nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','diligenceType',GlobalDataFactory.registerDiligenceTypes,'id'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','diligenceType') },
										{ id: 'observations', type: 'text', order: 11, label: 'global.literals.observations', preFilter:HelperService.getPrefilter('register','tableRegInputEntries','observations'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','observations') },
										{ id: 'withoutDocuments', type: 'checkbox', order: 12, label: 'global.literals.withoutDocuments', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','withoutDocuments'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','withoutDocuments')},
										{ id: 'onlyOriginEntryNumber', type: 'checkbox', order: 13, label: 'global.literals.onlyOriginEntryNumber', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','onlyOriginEntryNumber'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','onlyOriginEntryNumber')},
										{ id: 'originRegisterOrgan', type: 'text', order: 14, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableRegInputEntries','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','originRegisterOrgan') },
										{ id: 'address', type: 'text', order: 15, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegInputEntries','address'), negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','address') },
						        		{ id: 'createUser', type: 'text', order: 16, label: 'global.reg.literals.createUserReg', callAux: true, negated:HelperService.getPrefilterNegated('register','tableRegInputEntries','createUser') },
						        		{ id: 'historical', type: 'checkbox', order: 17, label: 'global.literals.showhistorical', callAux: true, preFilter:false, negated:false}
							        ]
							        if($rootScope.app.configuration.tram_classification_register_entry.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 17, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableRegInputEntries', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableRegInputEntries', 'tramClassification')})
							        }
							        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
							            filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), preFilter: HelperService.getPrefilter('register','tableRegInputEntries','registerEntryOffice',GlobalDataFactory.registerEntryOffices,'id'), negated: HelperService.getPrefilterNegated('register','tableRegInputEntries','registerEntryOffice') });
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										filterCall.entryType = 'INPUT';
							            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
	        							filterCallAux.langColumn = Language.getActiveColumn();
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'RegisterEntry', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
									}
								}
							}
						}		
					}
	            })
			}
			dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'REG_OUTPUT'").toArray();
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'pending'"))){
	        	var dsTrobat = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'pending'");
	        	if(!dsTrobat){
	                factory.regOutCounters.push({
	                    idState: 'pending',
		                permissions: ['view_notifications', 'view_all_notifications'],
	                    state: 'annexa.reg.output.notifications',
	                    stateParams: { status: 'PENDING' },
	                    title: 'global.literals.notPending',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/reg/dashboard/counters/notifications/PENDING'}).then(function(data) {
		            	var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'pending'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("pending") && data.data.pending > 0 )? data.data.pending : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'send'"))){
	        	var dsTrobat = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'send'");
	        	if(!dsTrobat){
	                factory.regOutCounters.push({
	                    idState: 'send',
		                permissions: ['view_notifications', 'view_all_notifications'],
	                    state: 'annexa.reg.output.notifications',
	                    stateParams: { status: 'SEND' },
	                    title: 'global.literals.notSend',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/reg/dashboard/counters/notifications/SEND'}).then(function(data) {
		            	var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'send'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("send") && data.data.send > 0 )? data.data.send : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
        	if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'error'"))){
	        	var dsTrobat = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'error'");
	        	if(!dsTrobat){
	                factory.regOutCounters.push({
	                    idState: 'error',
		                permissions: ['view_notifications', 'view_all_notifications'],
	                    state: 'annexa.reg.output.notifications',
	                    stateParams: { status: 'ERROR' },
	                    title: 'global.literals.notError',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/reg/dashboard/counters/notifications/ERROR'}).then(function(data) {
		            	var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == 'error'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("error") && data.data.error > 0 )? data.data.error : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState && x.idState === '"+item.id+"Filter'");
						 var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id+'Filter',
		                        state: ((item.filter.state)?item.filter.state:((item.filter.tableName === 'RegisterEntry')?'annexa.reg.output.entries':'annexa.reg.output.notifications')),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
		                        number: ''
		                    };
							if(!item.filter.state || item.filter.state.startsWith("annexa.reg.output")){
			                	factory.regOutCounters.push(itemAdded);
								if(itemAdded.state === 'annexa.reg.output.notifications'){
									var filter = [
							            { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','extract'), negated:HelperService.getPrefilterNegated('register','tableNots','extract')},
							            { id: 'notificationType', type: 'select', order: 2, label: 'global.literals.notificationTypes', dataType: 'LOCAL', data: GlobalDataFactory.notificationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','notificationType',GlobalDataFactory.notificationTypes,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','notificationType')},
							            { id: 'globalStatus', type: 'select', order: 3, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: NotificationFactory.notificationGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','globalStatus',NotificationFactory.notificationGlobalStatus,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','globalStatus')},
							            { id: 'status', type: 'select', order: 4, label: 'global.literals.particularStatus', dataType: 'LOCAL', data: GlobalDataFactory.notificationStatuses, nameProperty: Language.getActiveColumn(), addAll: true, callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','status',GlobalDataFactory.notificationStatuses,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','status')},
							            { id: 'entryNumber', type: 'text', order: 5, label: 'global.reg.datatable.numberOutput', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','entryNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','entryNumber')},
							            { id: 'dossierNumber', type: 'text', order: 6, label: 'global.literals.numExp', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','dossierNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','dossierNumber')},
							            { id: 'thirdName', type: 'text', order: 7, label: 'global.literals.receivers', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','thirdName'), negated:HelperService.getPrefilterNegated('register','tableNots','thirdName')},
							            { id: 'userCreator', type: 'text', order: 8, label: 'global.literals.creator', callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','userCreator'), negated:HelperService.getPrefilterNegated('register','tableNots','userCreator')},
							            { id: 'createdDate', type: 'dateRange', order: 9, label: 'global.literals.creation_date', callAux:true, preFilter:HelperService.getPrefilter('register','tableNots','createdDate'), negated:HelperService.getPrefilterNegated('register','tableNots','createdDate')},
							            { id: 'originEntryNumber', type: 'text', order: 10, label: 'global.literals.originEntryInputNumber', callAux:true, preFilter:HelperService.getPrefilter('register','tableNots','originEntryNumber'), negated:HelperService.getPrefilterNegated('register','tableNots','originEntryNumber') },
							            { id: 'originRegisterOrgan', type: 'text', order: 11, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableNots','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableNots','originRegisterOrgan') }
							        ];
							        if($rootScope.app.configuration.tram_classification_register_output.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 11, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableNots', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableNots', 'tramClassification')})
							        }
							        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
							            filter.splice(3, 0, { id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register','tableNots','registerEntryOffice',GlobalDataFactory.registerEntryOffices,'id'), negated:HelperService.getPrefilterNegated('register','tableNots','registerEntryOffice') });                                      
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            							filterCallAux.langColumn = Language.getActiveColumn();
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'NotificationThird', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
	
									}
								}else if(itemAdded.state === "annexa.reg.output.remittances"){
									var filter = [
							        	{ id: 'status', type: 'select', order: 1, label: 'global.remittance.literals.status', dataType: 'LOCAL', data: RemittanceFactory.remittanceGlobalStatus, nameProperty: 'name', addAll: true, callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','status',RemittanceFactory.remittanceGlobalStatus,'id'), negated:HelperService.getPrefilterNegated('register','tableRemittances','status')},
							        	{ id: 'remittanceNumber', type: 'text', order: 2, label: 'global.remittance.literals.number', callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','remittanceNumber'), negated:HelperService.getPrefilterNegated('register','tableRemittances','remittanceNumber')},
							        	{ id: 'notificationsCount', type: 'text', order: 3, label: 'global.remittance.literals.notificationsCount', callAux: true, preFilter:HelperService.getPrefilter('register','tableRemittances','notificationsCount'), negated:HelperService.getPrefilterNegated('register','tableRemittances','notificationsCount')},
							        	{ id: 'createdDate', type: 'dateRange', order: 4, label: 'global.remittance.literals.creation_date', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','createdDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','createdDate')},
							        	{ id: 'closeDate', type: 'dateRange', order: 5, label: 'global.remittance.literals.closeDate', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','closeDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','closeDate')},
							        	{ id: 'endDate', type: 'dateRange', order: 6, label: 'global.remittance.literals.endDate', callAux:true, preFilter:HelperService.getPrefilter('register','tableRemittances','endDate'), negated:HelperService.getPrefilterNegated('register','tableRemittances','endDate')}
							        ];
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            							filterCallAux.langColumn = Language.getActiveColumn();
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'Remittance', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
	
									}
								}else{
									var managed = [
							            { id: true, name: 'global.reg.literals.managed' },
							            { id: false, name: 'global.reg.literals.noManaged' }
							        ]
							        var filter = [
							            { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.numberOuput', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryNumber') },
							            { id: 'procedureName', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','procedureName'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','procedureName') },
							            { id: 'dossierNumber', type: 'text', order: 3, label: 'global.literals.numExp', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','dossierNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','dossierNumber') },
							            { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.allRegisterEntryChannelsOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','registerEntryChannel',GlobalDataFactory.allRegisterEntryChannelsOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','registerEntryChannel') },
							            { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','extract'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','extract') },
							            { id: 'dataReg', type: 'dateRange', order: 6, label: 'global.literals.regFrom', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','dataReg'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','dataReg') },
							            { id: 'entryDocumentType', type: 'select', order: 7, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryDocumentType',GlobalDataFactory.registerEntryDocumentTypesOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryDocumentType') },
							            { id: 'entryClassification', type: 'select', order: 8, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsOutput, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','entryClassification',GlobalDataFactory.registerEntryClassificationsOutput, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','entryClassification') },
							            { id: 'representant', type: 'text', order: 9, label: 'global.literals.destiny', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','representant'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','representant') },
							            { id: 'managed', type: 'select', order: 10, label: 'global.literals.state', dataType: 'LOCAL', data: managed, addAll: true, nameProperty: 'name', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','managed',managed, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','managed') },
							            { id: 'observations', type: 'text', order: 11, label: 'global.literals.observations', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','observations'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','observations') },
							            { id: 'originEntryNumber', type: 'text', order: 13, label: 'global.literals.originEntryInputNumber', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','originEntryNumber'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','originEntryNumber') },
							            { id: 'originRegisterOrgan', type: 'text', order: 14, label: 'global.literals.originEntryInputOrgan', preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','originRegisterOrgan'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','originRegisterOrgan') },
							            { id: 'withoutDocuments', type: 'checkbox', order: 14, label: 'global.literals.withoutDocuments', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','withoutDocuments'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','withoutDocuments')},
							            { id: 'section', type: 'select-tree', order: 15, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
							            { id: 'address', type: 'text', order: 16, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','address'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','address') },
							            { id: 'createUser', type: 'text', order: 18, label: 'global.reg.literals.createUserReg', callAux: true, preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','createUser'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','createUser') },
							            { id: 'historical', type: 'checkbox', order: 17, label: 'global.literals.showhistorical', callAux: true, preFilter:false, negated:false}
							        ];
							        if($rootScope.app.configuration.tram_classification_register_output.show) {
							        	filter.push({ id: 'tramClassification', type: 'select', order: 17, label: 'global.literals.tramClassification', dataType: 'LOCAL', data: GlobalDataFactory.tramClassifications, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('register', 'tableRegOutputEntries', 'tramClassification',GlobalDataFactory.tramClassifications,'id'), negated:HelperService.getPrefilterNegated('register', 'tableRegOutputEntries', 'tramClassification')})
							        }
							        if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
							            filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('register','tableRegOutputEntries','registerEntryOffice', GlobalDataFactory.registerEntryOffices, 'id'), negated:HelperService.getPrefilterNegated('register','tableRegOutputEntries','registerEntryOffice') });
							        }
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										filterCall.entryType = 'OUTPUT';
							            if(filterCall && filterCall.managed && (filterCall.managed.id || filterCall.managed.id === false)) {
                							filterCall.managed = filterCall.managed.id;
            							}
										var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
										filterCallAux.loggedUser = $rootScope.LoggedUser.id;
            							filterCallAux.langColumn = Language.getActiveColumn();
										return RestService.countersFilterDashboard(item.id+'', 'reg', 'RegisterEntry', filterCall, filterCallAux)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.regOutCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });
	
									}
								}
							}
						}		
					}
	            })
			}
			dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'SEC'").toArray();
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'propPending'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propPending'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'propPending',
		                permissions: ['view_proposals', 'review_proposals', 'valid_proposals', 'accept_proposals', 'amendment_proposals', 'cancel_proposals'],
	                    state: 'annexa.sec.proposals',
	                    stateParams: { status: [{id:'PENDING'}], tab:'all' },
	                    title: 'global.literals.propPending',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/proposal/dashboard/counters/PENDING' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propPending'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("pending") && data.data.pending > 0 )? data.data.pending : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
	                $rootScope.app.configuration.validation_circuit_secretary.value) {
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'propReview'"))){
		        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propReview'");
		        	if(!dsTrobat){
		                factory.secCounters.push({
		                    idState: 'propReview',
			                permissions: ['view_proposals', 'review_proposals'],
		                    state: 'annexa.sec.proposals',
		                    stateParams: { tab: 'review' },
		                    title: 'global.literals.propReview',
		                    number: ''
		                });
						$http({ method: 'GET', url: './api/sec/proposal/dashboard/counters/REVIEW' }).then(function(data) {
			            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propReview'");
							if(itemAdded){
		        				itemAdded.number = ((data.data && data.data.hasOwnProperty("review") && data.data.review > 0 )? data.data.review : '');
							}
			            }).catch(function(error) {
			            	
			            });
		        	}
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'propValidate'"))){
		        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propValidate'");
		        	if(!dsTrobat){
		                factory.secCounters.push({
		                    idState: 'propValidate',
			                permissions: ['view_proposals', 'valid_proposals'],
		                    state: 'annexa.sec.proposals',
		                    stateParams: { tab: 'validate' },
		                    title: 'global.literals.propValidate',
		                    number: ''
		                });
						$http({ method: 'GET', url: './api/sec/proposal/dashboard/counters/VALIDATE' }).then(function(data) {
			            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propValidate'");
							if(itemAdded){
		        				itemAdded.number = ((data.data && data.data.hasOwnProperty("validate") && data.data.validate > 0 )? data.data.validate : '');
							}
			            }).catch(function(error) {
			            	
			            });
		        	}
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'propAccept'"))){
		        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propAccept'");
		        	if(!dsTrobat){
		                factory.secCounters.push({
		                    idState: 'propAccept',
			                permissions: ['view_proposals', 'accept_proposals'],
		                    state: 'annexa.sec.proposals',
		                    stateParams: { tab: 'accept' },
		                    title: 'global.literals.propAccept',
		                    number: ''
		                });
						$http({ method: 'GET', url: './api/sec/proposal/dashboard/counters/ACCEPT' }).then(function(data) {
			            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'propAccept'");
							if(itemAdded){
		        				itemAdded.number = ((data.data && data.data.hasOwnProperty("accept") && data.data.accept > 0 )? data.data.accept : '');
							}
			            }).catch(function(error) {
			            	
			            });
		        	}
				}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'secPending'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secPending'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'secPending',
		                permissions: ['view_sessions'],
	                    state: 'annexa.sec.sessions',
	                    stateParams: { status: [{id:'PENDING'}] },
	                    title: 'global.literals.secPending',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/session/dashboard/counters/PENDING' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secPending'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("pending") && data.data.pending > 0 )? data.data.pending : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'secConvo'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secConvo'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'secConvo',
		                permissions: ['view_sessions'],
	                    state: 'annexa.sec.sessions',
	                    stateParams: { status: [{id:'CONVENED'}] },
	                    title: 'global.literals.secConvo',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/session/dashboard/counters/CONVENED' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secConvo'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("convened") && data.data.convened > 0 )? data.data.convened : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'secIni'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secIni'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'secIni',
		                permissions: ['view_sessions'],
	                    state: 'annexa.sec.sessions',
	                    stateParams: { status: [{id:'STARTED'}] },
	                    title: 'global.literals.secIni',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/session/dashboard/counters/STARTED' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secIni'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("started") && data.data.started > 0 )? data.data.started : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'secFin'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secFin'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'secFin',
		                permissions: ['view_sessions'],
	                    state: 'annexa.sec.sessions',
	                    stateParams: { status: [{id:'EXECUTED'}] },
	                    title: 'global.literals.secFin',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/session/dashboard/counters/EXECUTED' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'secFin'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("executed") && data.data.executed > 0 )? data.data.executed : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'decPending'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'decPending'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'decPending',
		                permissions: ['view_decrees'],
	                    state: 'annexa.sec.decrees',
	                    stateParams: { status: 'PENDING' },
	                    title: 'global.literals.decPending',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/decree/dashboard/counters/PENDING' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'decPending'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("pending") && data.data.pending > 0 )? data.data.pending : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'decSend'"))){
	        	var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'decSend'");
	        	if(!dsTrobat){
	                factory.secCounters.push({
	                    idState: 'decSend',
		                permissions: ['view_decrees'],
	                    state: 'annexa.sec.decrees',
	                    stateParams: { status: 'PENDING_SIGN' },
	                    title: 'global.literals.decSend',
	                    number: ''
	                });
					$http({ method: 'GET', url: './api/sec/decree/dashboard/counters/PENDING_SIGN' }).then(function(data) {
		            	var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == 'decSend'");
						if(itemAdded){
	        				itemAdded.number = ((data.data && data.data.hasOwnProperty("pending_sign") && data.data.pending_sign > 0 )? data.data.pending_sign : '');
						}
		            }).catch(function(error) {
		            	
		            });
	        	}
			}
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState && x.idState === '"+item.id+"Filter'");
						 var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
			                var itemAdded = {
		                        idState: item.id+'Filter',
		                        state: ((item.filter.state)?item.filter.state:((item.filter.tableName === 'Proposal')?'annexa.sec.proposals':((item.filter.tableName === 'Session')?'annexa.sec.sessions':'annexa.sec.decrees'))),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
		                        number: ''
		                    };
		                	factory.secCounters.push(itemAdded);
							if(itemAdded.state === 'annexa.sec.sessions'){
								var sessionStates = angular.copy(SecFactory.sessionStates);
						        var getOrganWithPermissions =  $linq(GlobalDataFactory.organs).where(function(x){
						        	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
                                		if(x.everyoneCanViewSessions === false) {
                                            if(x.usersViewSessions && x.usersViewSessions.length > 0) {
                                                var intersectedViewUsers = $linq(x.usersViewSessions).intersect($rootScope.LoggedUser, "(x,y) => y.user && x.id && y.user.id && y.user.id == x.id").toArray();
                                                if(intersectedViewUsers && intersectedViewUsers.length > 0) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                var intersectedProfiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
                                                if(intersectedProfiles && intersectedProfiles.length > 0){
                                                    return true;
                                                }else{
                                                    return false;
                                                }
                                            }
                                        } else {
                                            return true;
                                        }
                                	}else{
						        		return false;
						        	}
						        }).toArray();
								var filter = [
						        	{ id: 'state', type: 'select-multiple', order: 3, label: 'global.literals.state', dataType: 'LOCAL', data: sessionStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','state',sessionStates,'id'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','state') },
						            { id: 'organ', type: 'select', order: 0, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: getOrganWithPermissions, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','organ',getOrganWithPermissions,'id'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','organ') },
						            { id: 'sessionDateFirstConvene', type: 'dateRange', order: 10, label: 'global.sec.literals.sessionDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionDateFirstConvene'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionDateFirstConvene')  },
						            { id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionType',GlobalDataFactory.sessionTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionType')  },
						            { id: 'sessionNumber', type: 'text', order: 1, label: 'global.sec.literals.sessionNumber', preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionNumber'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionNumber')  }
						        ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter, true);
									return RestService.countersFilterDashboard(item.id+'', 'sec', 'Session', filterCall, filterCallAux)
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else if(itemAdded.state === 'annexa.sec.decrees'){
								var decreeStates = angular.copy(SecFactory.decreeStates);
        						var proposalTypes = angular.copy(GlobalDataFactory.proposalTypes);
						        proposalTypes = $linq(proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
						        var decreeDelegatedTypes = angular.copy(GlobalDataFactory.decreeDelegatedTypes);
 						        var filter = [
						        	{ id: 'state', type: 'select', order: 1, label: 'global.literals.state', dataType: 'LOCAL', data: decreeStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','state',decreeStates,'id'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','state') },
						        	{ id: 'decreeNumber', type: 'text', order: 2, label: 'global.sec.literals.decreeNumberAbrv', preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeNumber'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeNumber') },
						        	{ id: 'proposalType', type: 'select', order: 3, label: 'global.sec.literals.proposalType', dataType: 'LOCAL', data:proposalTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','proposalType',proposalTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','proposalType')  },
						            { id: 'proposalNumber', type: 'text', order: 4, label: 'global.sec.literals.proposalNumber', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','proposalNumber'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','proposalNumber')  },
						            { id: 'decreeDate', type: 'dateRange', order: 5, label: 'global.sec.literals.decreeDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeDate'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeDate')  },
						            { id: 'extract', type: 'text', order: 6, label: 'global.literals.extract', preFilter:HelperService.getPrefilter('secretary','tableDecrees','extract'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','extract')  },
									{ id: 'dossier', type: 'text', order: 7, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','dossier'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','dossier')  },
						        	{ id: 'decreeDelegatedType', type: 'select', order: 8, label: 'global.sec.literals.decreeDelegatedTypes', dataType: 'LOCAL', data:decreeDelegatedTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeDelegatedType',decreeDelegatedTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeDelegatedType')  }
						        ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter, true);
									return RestService.countersFilterDashboard(item.id+'', 'sec', 'Decree', filterCall, filterCallAux)
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined,"x => x.idState == '"+item.id+"Filter'");
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else {
								var pstates = angular.copy(SecFactory.proposalStates);
								var ptSubtypes = angular.copy(apiAdmin.proposalTypeSubtypes);
								var procedureFilter = angular.copy(GlobalDataFactory.procedures);
					            var profileFilter = angular.copy(GlobalDataFactory.allProfiles);
					            var sectionFilter = angular.copy(GlobalDataFactory.sections);
					            var procedureProposalTypeFilter = angular.copy(GlobalDataFactory.procedureProposalType);
								var filter = [
						        	{ id: 'state', type: 'select-multiple', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: pstates, addAll: false, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','state',pstates,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','state') },
						        	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalSubType', dataType: 'LOCAL', data:ptSubtypes , addAll: true, nameProperty: 'description', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','proposalType',ptSubtypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','proposalType')  },
						            { id: 'proposalNumber', type: 'text', order: 0, label: 'global.sec.literals.proposalNumber', preFilter:HelperService.getPrefilter('secretary','tableProposals','proposalNumber'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','proposalNumber')  },
						            { id: 'extract', type: 'text', order: 7, label: 'global.literals.title', preFilter:HelperService.getPrefilter('secretary','tableProposals','extract'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','extract') },
						            { id: 'dossier', type: 'text', order: 1, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','dossier'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','dossier')  },
						            { id: 'organ', type: 'select', order: 3, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: GlobalDataFactory.organs, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','organ',GlobalDataFactory.organs,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','organ') },
						            { id: 'organSessionNumber', type: 'text', order: 6, label: 'global.sec.literals.organSessionNumber', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','organSessionNumber'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','organSessionNumber')  },
						            { id: 'senderUser', type: 'text', order: 5, label: 'global.sec.literals.senderUser', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','senderUser'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','senderUser')  },
						            { id: 'sendDate', type: 'dateRange', order: 10, label: 'global.sec.literals.sendDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','sendDate'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','sendDate')  },
						            { id: 'section', type: 'select', order: 12, label: 'global.literals.section', dataType: 'LOCAL', data: sectionFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','section',GlobalDataFactory.sections,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','section')  },
					                { id: 'profile', type: 'select-multiple', order: 13, label: 'global.literals.profile', dataType: 'LOCAL', data: profileFilter, addAll: false, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','profile',GlobalDataFactory.allProfiles,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','profile') },
					            	{ id: 'procedure', type: 'select', order: 14, label: 'global.literals.procedure', dataType: 'LOCAL', data: procedureFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','procedure',GlobalDataFactory.procedures,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','procedure')  },
					            	{ id: 'procedureProposalType', type: 'select', order: 15, label: 'global.sec.literals.procedureProposalType', dataType: 'LOCAL', data: procedureProposalTypeFilter , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableProposals','procedureProposalType',GlobalDataFactory.procedureProposalType,'id'), negated:HelperService.getPrefilterNegated('secretary','tableProposals','procedureProposalType')  }
						        ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter, true);
									return RestService.countersFilterDashboard(item.id+'', 'sec', 'Proposal', filterCall, filterCallAux)
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.secCounters).firstOrDefault(undefined, "x => x.idState == '"+item.id+"Filter'");
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}
						}		
					}
	            })
			}
			dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'TASKS'").toArray();
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'myTask'"))){
				var item = {
	                idState: 'myTask',
					state: 'annexa.tasks.assignedTasks',
	                stateParams: {type: 'A'},
	                title: 'global.task.myTask',
	                column: 1,
	                number: '',
	                isGroup: true
	            };
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
	        	}
	        	var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
	        	if(!dsTrobat){
	        		factory.taskSimpleCounters.push(item);
	        	}
        	}
			var totalAssignedwithinDeadline = 0;
			var totalAssignedoutOfTime = 0;
			var totalAssignedpendingTasks = 0;
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'withinDeadline'"))){
				var item = {
	                idState: 'withinDeadline',
					state: 'annexa.tasks.assignedTasks',
	                stateParams: {type: 'ANE'},
	                title: 'global.literals.withinDeadline',
	                column: 1,
	                number: '',
	                isChild: true
	            };
				var addedAssignedwithinDeadline = false;
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline'");
	        	if(!dsTrobat){
	                factory.taskFullCounters.push(item);
					addedAssignedwithinDeadline = true;
	        	}
				var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline'");
	        	if(!dsTrobat){
	        		factory.taskSimpleCounters.push(item);
					addedAssignedwithinDeadline = true;
	        	}
				if(addedAssignedwithinDeadline){
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/AssignedNoExpired' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedNoExpired") && data.data.AssignedNoExpired > 0 )? data.data.AssignedNoExpired : '');
							totalAssignedwithinDeadline = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedNoExpired") && data.data.AssignedNoExpired > 0 )? data.data.AssignedNoExpired : '');
							totalAssignedwithinDeadline = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
					}).catch(function(error) {
		            	
		            });

				}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'outOfTime'"))){
				var item = {
	                idState: 'outOfTime',
					state: 'annexa.tasks.assignedTasks',
	                stateParams: {type: 'AE'},
	                title: 'global.literals.outOfTime',
	                column: 1,
	                number: '',
	                isChild: true
	            };
				var addedAssignedoutOfTime = false;
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					addedAssignedoutOfTime = true;
	        	}
	            var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
	        	if(!dsTrobat){
	        		factory.taskSimpleCounters.push(item);
					addedAssignedoutOfTime = true;
	        	}
				if(addedAssignedoutOfTime){
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/AssignedExpired' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedExpired") && data.data.AssignedExpired > 0 )? data.data.AssignedExpired : '');
							totalAssignedoutOfTime = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedExpired") && data.data.AssignedExpired > 0 )? data.data.AssignedExpired : '');
							totalAssignedoutOfTime = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
				}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'pendingTasks'"))){
				var item = {
	                idState: 'pendingTasks',
					state: 'annexa.tasks.assignedTasks',
	                stateParams: {type: 'AP'},
	                title: 'global.task.pendingTasks',
	                column: 1,
	                number: '',
	                isChild: true
	            };
				var addedAssignedpendingTasks = false;
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks'");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					addedAssignedpendingTasks = true;
	        	}
	        	var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks'");
	        	if(!dsTrobat){
	        		factory.taskSimpleCounters.push(item);
					addedAssignedpendingTasks = true;
	        	}
				if(addedAssignedpendingTasks){
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/AssignedPending' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedPending") && data.data.AssignedPending > 0 )? data.data.AssignedPending : '');
							totalAssignedpendingTasks = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks'");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("AssignedPending") && data.data.AssignedPending > 0 )? data.data.AssignedPending : '');
							totalAssignedpendingTasks = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
						itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == 'myTask'");
						if(itemAdded){
							itemAdded.number = totalAssignedwithinDeadline + totalAssignedoutOfTime + totalAssignedpendingTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
				}
			}
			var totalCreatedwithinDeadline = 0;
			var totalCreatedoutOfTime = 0;
			var totalCreatedpendingTasks = 0;
			var totalCreatedfinalizedTasks = 0;
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'createdTask'"))){
				var item = {
	                idState: 'createdTask',
					state: 'annexa.tasks.createdTasks',
	                stateParams: {type: 'C'},
	                title: 'global.task.createdTask',
	                column: 2,
	                number: '',
	                isGroup: true
	            };
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'createdTask' && x.column == 2");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
	        	}
            }
            if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'withinDeadline2'"))){
				var item ={
	                idState: 'withinDeadline2',
					state: 'annexa.tasks.createdTasks',
	                stateParams: {type: 'CNE'},
	                title: 'global.literals.withinDeadline',
	                column: 2,
	                number: '',
	                isChild: true
	            };
	
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline2' && x.column == 2");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/CreatedNoExpired' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'withinDeadline2' && x.column == 2");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("CreatedNoExpired") && data.data.CreatedNoExpired > 0 )? data.data.CreatedNoExpired : '');
							totalCreatedwithinDeadline = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'createdTask'");
						if(itemAdded){
							itemAdded.number = totalCreatedwithinDeadline + totalCreatedoutOfTime + totalCreatedpendingTasks + totalCreatedfinalizedTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
	        	}
            }
            if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'outOfTime2'"))){
				var item = {
	                idState: 'outOfTime2',
					state: 'annexa.tasks.createdTasks',
	                stateParams: {type: 'CE'},
	                title: 'global.literals.outOfTime',
	                column: 2,
	                number: '',
	                isChild: true
	            };
	
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime2' && x.column == 2");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/CreatedExpired' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'outOfTime2' && x.column == 2");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("CreatedExpired") && data.data.CreatedExpired > 0 )? data.data.CreatedExpired : '');
							totalCreatedoutOfTime = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'createdTask'");
						if(itemAdded){
							itemAdded.number = totalCreatedwithinDeadline + totalCreatedoutOfTime + totalCreatedpendingTasks + totalCreatedfinalizedTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
	        	}
            }
            if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'pendingTasks2'"))){
				var item = {
	                idState: 'pendingTasks2',
					state: 'annexa.tasks.createdTasks',
	                stateParams: {type: 'CP'},
	                title: 'global.task.pendingTasks',
	                column: 2,
	                number: '',
	                isChild: true
	            };

	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks2' && x.column == 2");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/CreatedPending' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'pendingTasks2' && x.column == 2");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("CreatedPending") && data.data.CreatedPending > 0 )? data.data.CreatedPending : '');
							totalCreatedpendingTasks = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'createdTask'");
						if(itemAdded){
							itemAdded.number = totalCreatedwithinDeadline + totalCreatedoutOfTime + totalCreatedpendingTasks + totalCreatedfinalizedTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
	        	}
            }
            if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'finalizedTasks'"))){
				var item = {
	                idState: 'finalizedTasks',
					state: 'annexa.tasks.createdTasks',
	                stateParams: {type: 'CF'},
	                title: 'global.task.finalizedTasks',
	                column: 2,
	                number: '',
	                isChild: true
	            };
	
	            var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'finalizedTasks' && x.column == 2");
	        	if(!dsTrobat){
	        		factory.taskFullCounters.push(item);
					$http({ method: 'GET', url: './api/tasks/dashboard/counters/full/CreatedFinished' }).then(function(data) {
						var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'finalizedTasks' && x.column == 2");
						if(itemAdded){
	           				itemAdded.number = ((data.data && data.data.hasOwnProperty("CreatedFinished") && data.data.CreatedFinished > 0 )? data.data.CreatedFinished : '');
							totalCreatedfinalizedTasks = ((itemAdded.number && itemAdded.numbe !== '' && !Number.isNaN(itemAdded.number))?Number(itemAdded.number):0);;
						}
						itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == 'createdTask'");
						if(itemAdded){
							itemAdded.number = totalCreatedwithinDeadline + totalCreatedoutOfTime + totalCreatedpendingTasks + totalCreatedfinalizedTasks;
	           			}
					}).catch(function(error) {
		            	
		            });
	        	}
			}
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+item.id);
						var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id,
		                        state: ((item.filter.state)?item.filter.state:'annexa.tasks.assignedTasks'),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
		                        column: ((!item.filter.state || (item.filter.state && item.filter.state === 'annexa.tasks.assignedTasks'))?1:2),
	                			number: '',
								isChild: true
		                    };
		                	factory.taskFullCounters.push(itemAdded);
							var expiredStates = [
				                { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
				                { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
				            ];
				
				            var taskStatuses = TaskFactory.taskStatuses;
							var getSpecificStatuses = function (globalStatusList) {
				                var specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).distinctBy("x => x." + Language.getActiveColumn()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
				                if (globalStatusList && globalStatusList.length > 0) {
				                	specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).where(function(status){
					        			return $linq(globalStatusList).contains(status.globalStatus, function(x,y){
					        				if(x && x.id && y == x.id){
					        					return true;
					        				}else{
					        					return false;
					        				}
					        			});
				            		}).distinctBy("x => x." + Language.getActiveColumn()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
				                }
				
				                return HelperService.addAllSelect(angular.copy(specificStatuses), Language.getActiveColumn(), Language.getActiveColumn());
				            }
							if(itemAdded.state === 'annexa.tasks.createdTasks'){
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true , preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','thirdName')},
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','usersName') },
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','address') },
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','email') },
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','allowedCreateExternally')}
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = false;
					                filterCallAux.manage = false;
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data =getSpecificStatuses(filterCallAux.status);
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }
					                }
									return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "createdTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else if(itemAdded.state === 'annexa.tasks.manageTasks'){
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','thirdName') },
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','usersName') },
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','address') },
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','email') },
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','allowedCreateExternally')}
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = false;
					                filterCallAux.manage = true;
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }
					                }
									return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "managedTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined,"x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else {
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','thirdName') },                
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','usersName') },                
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','address') },                
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','email') }, 
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','allowedCreateExternally')}
					                
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = true;
					                filterCallAux.manage = false;
					
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }else{
					                        filterCallAux.expired = undefined;
					                    }
					                }
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);
					
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
						            return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "assignedTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskFullCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}		                
						}	
						var dsTrobat = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+item.id);
						var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							var itemAdded = {
		                        idState: item.id,
		                        state: ((item.filter.state)?item.filter.state:'annexa.tasks.assignedTasks'),
		                        stateParams: {filterId: item.filter.id},
								title: item.filter.name,
								column: 1,
		                        number: '',
								isChild: true
		                    };
		                	factory.taskSimpleCounters.push(itemAdded);
							var expiredStates = [
				                { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
				                { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
				            ];
				
				            var taskStatuses = TaskFactory.taskStatuses;
							var getSpecificStatuses = function (globalStatusList) {
				                var specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).distinctBy("x => x." + Language.getActiveColumn()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
				                if (globalStatusList && globalStatusList.length > 0) {
				                	specificStatuses = $linq(GlobalDataFactory.taskTypeStatuses).where(function(status){
					        			return $linq(globalStatusList).contains(status.globalStatus, function(x,y){
					        				if(x && x.id && y == x.id){
					        					return true;
					        				}else{
					        					return false;
					        				}
					        			});
				            		}).distinctBy("x => x." + Language.getActiveColumn()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
				                }
				
				                return HelperService.addAllSelect(angular.copy(specificStatuses), Language.getActiveColumn(), Language.getActiveColumn());
				            }
							if(itemAdded.state === 'annexa.tasks.createdTasks'){
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true , preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','thirdName')},
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','usersName') },
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','address') },
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','email') },
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableCreatedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableCreatedTasks','allowedCreateExternally')}
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = false;
					                filterCallAux.manage = false;
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data =getSpecificStatuses(filterCallAux.status);
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }
					                }
									return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "createdTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else if(itemAdded.state === 'annexa.tasks.manageTasks'){
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','thirdName') },
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','usersName') },
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','address') },
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','email') },
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableManagedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableManagedTasks','allowedCreateExternally')}
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = false;
					                filterCallAux.manage = true;
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }
					                }
									return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "managedTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined,"x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}else {
								var filter = [
					            	{ id: 'taskNumber', type: 'text', order: 0, label: 'global.literals.code', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','taskNumber'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','taskNumber') },
					                { id: 'description', type: 'text', order: 1, label: 'global.literals.task_name', preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','description'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','description') },
					                { id: 'createdUser', type: 'text', order: 2, label: 'global.literals.started_for', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','createdUser'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','createdUser')},
					                { id: 'user', type: 'text', order: 3, label: 'global.literals.assigned_to', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','user'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','user')},
					                { id: 'startDates', type: 'dateRange', order: 4, label: 'global.literals.startDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','startDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','startDates')},
					                { id: 'endDates', type: 'dateRange', order: 5, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','endDates'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','endDates')},
					                { id: 'expired', type: 'select', order: 6, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy(expiredStates), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','expired',expiredStates,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','expired')},
					                { id: 'type', type: 'select', order: 7, label: 'global.literals.type', dataType: 'LOCAL', data: angular.copy(GlobalDataFactory.taskTypes), addAll: true, nameProperty: Language.getActiveColumn(), preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','type',GlobalDataFactory.taskTypes,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','type')},
					                { id: 'status', type: 'select-multiple', order: 8, label: 'global.literals.globalStatus', dataType: 'LOCAL', data: angular.copy(taskStatuses), addAll: true, nameProperty: 'name', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','status',taskStatuses,'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','status')},
					                { id: 'specificStatus', type: 'select-multiple', order: 9, label: 'global.literals.status', dataType: 'LOCAL', data: getSpecificStatuses(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','specificStatus',getSpecificStatuses(),'id'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','specificStatus')},
					                { id: 'thirdName', type: 'text', order: 10, label: 'global.thirds.literals.third', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','thirdName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','thirdName') },                
					                { id: 'usersName', type: 'text', order: 11, label: 'global.users.list.boxTaskUsersToptitle', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','usersName'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','usersName') },                
					                { id: 'address', type: 'text', order: 12, label: 'global.territory.list.address', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','address'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','address') },                
					                { id: 'email', type: 'text', order: 13, label: 'global.tasks.literals.email', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','email'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','email') }, 
					                { id: 'phone', type: 'text', order: 14, label: 'global.tasks.literals.phone', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','phone'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','phone') },
					                { id: 'meta', type: 'text', order: 15, label: 'global.literals.meta', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','meta'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','meta') },
									{ id: 'dossier', type: 'text', order: 16, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','dossier'), negated:HelperService.getPrefilterNegated('tasks','tableAssignedTasks','dossier') },
					                { id: 'subtasks', type: 'checkbox', order: 17, label: 'global.tasks.literals.alsoSubtasks', callAux: true, preFilter: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','subtasks')},
					                { id: 'duplicatedTasks', type: 'checkbox', order: 18, label: 'global.tasks.literals.alsoDuplicatedTasks', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','duplicatedTasks')},
					                { id: 'noThirds', type: 'checkbox', order: 19, label: 'global.tasks.literals.noThirds', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noThirds')},
					                { id: 'noAffectedUsers', type: 'checkbox', order: 20, label: 'global.users.list.noAffectedUsers', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','noAffectedUsers')},
					                { id: 'allowedCreateExternally', type: 'checkbox', order: 21, label: 'global.tasks.literals.allowedCreateExternally', callAux: true, preFilter:HelperService.getPrefilter('tasks','tableAssignedTasks','allowedCreateExternally')}
					                
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCall = TableFilterFactory.getFilterCall(filter);
									var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.loggedUser = $rootScope.LoggedUser.id;
					                filterCallAux.assigned = true;
					                filterCallAux.manage = false;
					
					                if(filterCallAux.expired){
					                    if(filterCallAux.expired.id == 'expired'){
					                        filterCallAux.expired = true;
					                    }else if(filterCallAux.expired.id == 'noExpired'){
					                        filterCallAux.expired = false;
					                    }else{
					                        filterCallAux.expired = undefined;
					                    }
					                }
									var filterSpecificStatusAux = $linq(filter).singleOrDefault(undefined, "x => x.id == 'specificStatus'");
					   				filterSpecificStatusAux.data = getSpecificStatuses(filterCallAux.status);
					
					   				if(filterCallAux.specificStatus && filterCallAux.specificStatus.length > 0){
					   					var specificListFilter = [];
					   	                _.forEach(filterCallAux.specificStatus, function(value, index) {
					   	   					if($linq(filterSpecificStatusAux.data).contains(value, function(x,y){
					   	   						if(x && x.id && y.id == x.id) { return true; } else { return false; } 
					   	   					})) {
					   	   						specificListFilter.push({id: value[Language.getActiveColumn()]});
					   	   					}
					   	                });
					
					   	                //Actualitzem el model amb els valors carregats al llistat specificListFilter
					   	                filterSpecificStatusAux.model = $linq(filterSpecificStatusAux.model).where(function(specificStatus){
						        			return $linq(specificListFilter).contains(specificStatus[Language.getActiveColumn()], function(x,y){
						        				if(x && x.id && y == x.id) { return true; } else { return false; }
						        			});
					   	                }).toArray();
					   	                
					   	                filterCallAux.specificStatus = specificListFilter;
					        		} else {
					    				filterSpecificStatusAux.model = undefined;
					        		}
						            return RestService.countersFilterDashboard(item.id+'', 'tasks', 'TaskView', filterCall, filterCallAux, "assignedTasks")
		                                .then(function (data) {
		                                    var itemAdded = $linq(factory.taskSimpleCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
											if(itemAdded){
					            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
											}
		                                });

								}
							}
						}		
					}
	            })
			}

        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
				dataDec = [];
				if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
					dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'ARCHIVE'").toArray();
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'finally'"))){
		    		var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'finally'");
		        	if(!dsTrobat){
		                factory.archiveCounters.push({
		                    idState: 'finally',
							state: 'annexa.archive.finally',
		                    title: 'global.archive.finally.title',
		                    number: '',
		                    permissions: ['archive_finally']
		                });
						$http({ method: 'GET', url: './api/tram/archive/finallyCounters' }).then(function(data) {
							var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'finally'");
							if(itemAdded){
	            				itemAdded.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
							}
			            }).catch(function(error) {
			            	
			            });
		        	}
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'close'"))){
		        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'close'");
		        	if(!dsTrobat){
		                factory.archiveCounters.push({
		                    idState: 'close',
							state: 'annexa.archive.close',
		                    title: 'global.archive.close.title',
		                    number: '',
		                    permissions: ['archive_close']
		                });
						$http({ method: 'GET', url: './api/tram/archive/closeCounters' }).then(function(data) {
							var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'close'");
							if(itemAdded){
				            	itemAdded.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
							}
			            }).catch(function(error) {
			            	
			            });
		        	}
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'transfer'"))){
	        		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
			        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'transfer'");
			        	if(!dsTrobat){
			                factory.archiveCounters.push({
			                    idState: 'transfer',
								state: 'annexa.archive.transfer',
			                    title: 'global.archive.transfer.title',
			                    number: '',
			                    permissions: ['archive_transfer']
			                });
							$http({ method: 'GET', url: './api/tram/archive/transferCounters' }).then(function(data) {
								var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'transfer'");
								if(itemAdded){
		            				itemAdded.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
								}
				            }).catch(function(error) {
				            	
				            });
			        	}
					}
				}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'rejected'"))){
		        	if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
				        var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'rejected'");
			        	if(!dsTrobat){
			                factory.archiveCounters.push({
			                    idState: 'rejected',
								state: 'annexa.archive.rejected',
			                    title: 'global.archive.rejected.title',
			                    number: '',
			                    permissions: ['archive_rejected']
			                });
							$http({ method: 'GET', url: './api/tram/archive/rejectCounters' }).then(function(data) {
								var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'rejected'");
								if(itemAdded){
					            	itemAdded.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
								}
				            }).catch(function(error) {
				            	
				            });
			        	}
					}
        		}
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'archive'"))){
	        		if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE' || $rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
			        	var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'archive'");
			        	if(!dsTrobat){
							factory.archiveCounters.push({
			                    idState: 'archive',
								state: 'annexa.archive.archive',
			                    title: 'global.archive.archive.title',
			                    number: '',
			                    permissions: ['archive_archive']
			                });
							$http({ method: 'GET', url: './api/tram/archive/archiveCounters' }).then(function(data) {
								var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == 'archive'");
								if(itemAdded){
		            				itemAdded.number = ((data.data && data.data.hasOwnProperty("Number") && data.data.Number > 0 )? data.data.Number : '');
								}
				            }).catch(function(error) {
				            	
				            });
			        	}
	        		}
				}
				if(dataDec && dataDec.length > 0){
					_.forEach(dataDec, function(item) {
						if(item.filter && item.filter.id && !item.filter.deleted){
							var dsTrobat = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState && x.idState === "+item.id);
							 var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
			            	if(!dsTrobat && dsUsersTrobat){
				                var itemAdded = {
			                        idState: item.id,
			                        state:  ((item.filter.state)?item.filter.state:'annexa.archive.finally'),
			                        stateParams: {filterId: item.filter.id},
									title: item.filter.name,
			                        number: '',
									permissions: ['archive_finally']
			                    };
								if(!item.filter.state || item.filter.state.startsWith("annexa.archive")){
			                		factory.archiveCounters.push(itemAdded);
									var allProfilesBasic = angular.copy(GlobalDataFactory.allProfiles);
							        $linq(allProfilesBasic).foreach(function (x) { 
							        	delete x['userProfiles'];
							        	delete x['ficticio'];
							        });
									var filter = [
								    	{ id: 'responsibleTramitProfile', type: 'select-multiple', order: 0, label: 'global.literals.responsibleProfiles',  dataType: 'LOCAL', data: new SortedArray(allProfilesBasic,Language.getActiveColumn()).sort(), addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','responsibleTramitProfile', allProfilesBasic, 'id'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','responsibleTramitProfile')},
								    	{ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.code', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','dossierNumber'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','dossierNumber')},
								    	{ id: 'procedure', type: 'text', order: 2, label: 'global.literals.procedure', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','procedure'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','procedure')},
								    	{ id: 'subject', type: 'text', order: 3, label: 'global.literals.subject', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','subject'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','subject')}
								    ];
									if(itemAdded.state === 'annexa.archive.finally'){
								    	filter.push({ id: 'endDate', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','endDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','endDate') });
								    	filter.push({ id: 'expiryCloseDate', type: 'dateRange', order: 5, label: 'global.archive.finally.expiryCloseDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','expiryCloseDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','expiryCloseDate') });
									}else if(itemAdded.state === 'annexa.archive.close'){
								    	filter.push({ id: 'closeDate', type: 'dateRange', order: 4, label: 'global.archive.close.closeDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','closeDate') , negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','closeDate')});
										if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.close_and_archive){
						    				if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_GEE'){
						    					filter.push({ id: 'transferExpireDate', type: 'dateRange', order: 5, label: 'global.archive.close.expiryTransferDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','transferExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','transferExpireDate') });
						    				}else if($rootScope.app.configuration.close_and_archive.type == 'ARCHIVE_WITH_TOOL'){
						    					filter.push({ id: 'archiveExpireDate', type: 'dateRange', order: 5, label: 'global.archive.close.expiryArchiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveClose','archiveExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveClose','archiveExpireDate') });
						    				}
										}
									}else if(itemAdded.state === 'annexa.archive.transfer'){
										filter.push({ id: 'transferDate', type: 'dateRange', order: 4, label: 'global.archive.transfer.transferDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveTransfer','transferDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveTransfer','transferDate') });
									}else if(itemAdded.state === 'annexa.archive.rejected'){
										filter.push({ id: 'rejectedDate', type: 'dateRange', order: 4, label: 'global.archive.rejected.rejectedDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveRejected','rejectedDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveRejected','rejectedDate') });
									}else if(itemAdded.state === 'annexa.archive.archive'){
										filter.push({ id: 'archiveDate', type: 'dateRange', order: 4, label: 'global.archive.archive.archiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDate') });
										filter.push({ id: 'archiveDefinitiveExpireDate', type: 'dateRange', order: 5, label: 'global.archive.archive.archiveDefinitiveExpireDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDefinitiveExpireDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDefinitiveExpireDate') });
										filter.push({ id: 'archiveDefinitiveDate', type: 'dateRange', order: 6, label: 'global.archive.archive.archiveDefinitiveDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveArchive','archiveDefinitiveDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveArchive','archiveDefinitiveDate') });
									}else{
								    	filter.push({ id: 'endDate', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','endDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','endDate') });
								    	filter.push({ id: 'expiryCloseDate', type: 'dateRange', order: 5, label: 'global.archive.finally.expiryCloseDate', callAux: true, preFilter:HelperService.getPrefilter('tramitation','tableArchiveFinally','expiryCloseDate'), negated:HelperService.getPrefilterNegated('tramitation','tableArchiveFinally','expiryCloseDate') });
									}
									if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
										HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
										var filterCall = TableFilterFactory.getFilterCall(filter);
										var filterCallAux = TableFilterFactory.getFilterCallAux(filter, true);
										if(filterCallAux){
											filterCallAux.filterOrigin = ((itemAdded.state === 'annexa.archive.finally')?'archiveFinally': ((itemAdded.state === 'annexa.archive.close')?'archiveClose':((itemAdded.state === 'annexa.archive.transfer')?'archiveTransfer':((itemAdded.state === 'annexa.archive.rejected')?'archiveRejected':((itemAdded.state === 'annexa.archive.archive')?'archiveArchive':'archiveFinally')))))
										}
										return RestService.countersFilterDashboard(item.id+'', 'tram', 'Dossier', filterCall, filterCallAux, filterCallAux.filterOrigin)
			                                .then(function (data) {
			                                    var itemAdded = $linq(factory.archiveCounters).firstOrDefault(undefined, "x => x.idState == "+item.id);
												if(itemAdded){
						            				itemAdded.number = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
												}
			                                });

									}
								}
							}		
						}
		            })
				}
        	}
        	deferrend.resolve('OK');
            return deferrend.promise;
            
        }

        return factory;
    }])
    .component('annexaDashboard', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard.html',
        controller:['annexaDashboardFactory', 'GlobalDataFactory', function(annexaDashboardFactory, GlobalDataFactory) {
            var vm = this;

            vm.documentCounters = annexaDashboardFactory.documentCounters
            vm.tramCounters = annexaDashboardFactory.tramCounters;
            vm.regCounters = annexaDashboardFactory.regCounters;
            vm.regOutCounters = annexaDashboardFactory.regOutCounters;
            vm.taskFullCounters = annexaDashboardFactory.taskFullCounters;
            vm.taskSimpleCounters = annexaDashboardFactory.taskSimpleCounters;
            vm.archiveCounters = annexaDashboardFactory.archiveCounters;
			vm.secCounters = annexaDashboardFactory.secCounters;
            
            vm.docModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.doc.documents.all'");
            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign.pending'");
            vm.tramModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tram'");
            vm.regModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.reg.input'");
            vm.regOutModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.reg.output'");
            vm.taskModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.tasks'");
            vm.archiveModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.archive'");
			vm.secModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sec.proposals'");
        }]
    })
    .component('annexaDashboardBox', {
        transclude: true,
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box.html',
        controller: ['GlobalDataFactory', 'Language', 'globalModals', 'AnnexaFormlyFactory', '$filter', '$http', 'DialogsFactory', 'CacheFactory', '$state', '$rootScope', function(GlobalDataFactory, Language, globalModals, AnnexaFormlyFactory, $filter, $http, DialogsFactory, CacheFactory, $state, $rootScope) {
            var vm = this;
			vm.languageColumn = Language.getActiveColumn();
            vm.columnOneCounters = [];
            vm.columnTwoCounters = []
			var saveModalSettings = function(){
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var objectsToUpdate = [];
					var keys = Object.keys(this.annexaFormly.model.modal_body);
					if(keys && keys.length > 0){
						_.forEach(keys, function(key){
							if(key.startsWith("counters_filter_")){
								objectsToUpdate.push({filter:{id:key.substring("counters_filter_".length, key.length)}, counterType:vm.boxType, active:model[key]});
							}else if(key.startsWith("counters_")){
								objectsToUpdate.push({name:key.substring("counters_".length, key.length), counterType:vm.boxType, active:model[key]});
							}
						});
					}
					$http({ method: 'POST', url: './api/user_counter/my_counters/'+vm.boxType, data: JSOG.encode(objectsToUpdate) }).then(function(data) {
						if(GlobalDataFactory.userCountersConfiguration){
                        	GlobalDataFactory.userCountersConfiguration.length = 0;
                        }else{ 
                        	GlobalDataFactory.userCountersConfiguration = [];
                        }
                        if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('userCountersConfiguration')){
                        	CacheFactory.get('globalDataCache').remove('userCountersConfiguration')
                        }
                        GlobalDataFactory.loadGlobalData().then(function(data) {
							$state.reload();
		            	}).catch(function(error) {
							$state.reload();
			            });
					}).catch(function(error) {
						DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
		            });
				}
			}
			vm.showSettings = function(){
				if(vm.boxType && _.contains(['DOC','SIGN','TRAM','REG_INPUT','REG_OUTPUT','TASKS','ARCHIVE','SEC'], vm.boxType)){
					var modalSettings = angular.copy(globalModals.dashboardSettings);	
					modalSettings.annexaFormly.options = {};
					modalSettings.annexaFormly.model = {modal_body:{}};
					var countersField = $linq(modalSettings.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'counters'");
					if(countersField && countersField.templateOptions){
						if(countersField.templateOptions.options){
							countersField.templateOptions.options.length = 0;
						}else{
							countersField.templateOptions.options = [];	
						}
						var dataDec = [];
						if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
							dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.counterType == '"+vm.boxType+"'").toArray();
							if(dataDec && dataDec.length > 0){
								_.forEach(dataDec, function(dd){
									if(dd.name){
										modalSettings.annexaFormly.model.modal_body["counters_"+dd.name] = dd.active;
									}else if(dd.filter && dd.filter.id){
										modalSettings.annexaFormly.model.modal_body["counters_filter_"+dd.filter.id] = dd.active;
									}
								});
							}
						}
						if(vm.boxType === 'DOC'){
							_.forEach(GlobalDataFactory.documentStatuses, function(item) {
				            	if(!item.hide && item.id){
				                    countersField.templateOptions.options.push({id: item.id , title: $filter('translate')('global.documentState.' + item.id)});
									if(!dataDec || dataDec.length == 0){
										modalSettings.annexaFormly.model.modal_body["counters_"+item.id] = true;
									}
				            	}
				            })
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName && x.tableName.startsWith('Document')").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
										if(!dataDec || dataDec.length == 0){
											modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
										}	
									});
								}
							}
						}else if(vm.boxType === 'SIGN'){
							_.forEach(GlobalDataFactory.signActionTypes, function(item) {
			                    countersField.templateOptions.options.push({id: item.id , title: item[vm.languageColumn]});
								if(!dataDec || dataDec.length == 0){
									modalSettings.annexaFormly.model.modal_body["counters_"+item.id] = true;
								}
				            })
							countersField.templateOptions.options.push({id: "urgent" , title: $filter('translate')('global.sign.urgents')});
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_urgent"] = true;
							}
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'SignProcessAction' ").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
										if(!dataDec || dataDec.length == 0){
											modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
										}	
									});
								}
							}
						}else if(vm.boxType === 'TRAM'){
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_tramPendingTramitation"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_outOfTime"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_tramFavorite"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_tramPendingSee"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_tramFinally"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_dossierRightAccess"] = true;
							}
							countersField.templateOptions.options.push({id:'tramPendingTramitation', title: $filter('translate')('global.literals.tramPendingTramitation')});
							countersField.templateOptions.options.push({id:'outOfTime', title: $filter('translate')('global.literals.outOfTime')});
							countersField.templateOptions.options.push({id:'tramFavorite', title: $filter('translate')('global.literals.tramFavorite')});
							countersField.templateOptions.options.push({id:'tramPendingSee', title: $filter('translate')('global.literals.tramPendingSee')});
							countersField.templateOptions.options.push({id:'tramFinally', title: $filter('translate')('global.literals.tramFinally')});
							countersField.templateOptions.options.push({id:'dossierRightAccess', title: $filter('translate')('global.tram.tabs.dossierRightAccess.title')});
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'DossierTransaction' ||  x.tableName === 'Dossier'").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										if(!f.state || f.state.startsWith("annexa.tram")){
											countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
											if(!dataDec || dataDec.length == 0){
												modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
											}	
										}
									});
								}
							}
				        }else if(vm.boxType === 'REG_INPUT'){
							_.forEach(GlobalDataFactory.registerDiligenceTypes, function(diligenceType) {
								var titleDilig = $filter('translate')('global.literals.dashboardDiligenceType', { diligenceType: diligenceType[vm.languageColumn]});
				            	countersField.templateOptions.options.push({id: diligenceType.id , title: titleDilig});
								if(!dataDec || dataDec.length == 0){
									modalSettings.annexaFormly.model.modal_body["counters_"+diligenceType.id] = true;
								}
				            })
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'RegisterEntry' || x.tableName === 'RegisterEntryDiligenceView'").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										if(!f.state || f.state.startsWith("annexa.reg.input")){
											countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
											if(!dataDec || dataDec.length == 0){
												modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
											}
										}	
									});
								}
							}
						}else if(vm.boxType === 'REG_OUTPUT'){
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_pending"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_send"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_error"] = true;
							}
							countersField.templateOptions.options.push({id:'pending', title: $filter('translate')('global.literals.notPending')});
							countersField.templateOptions.options.push({id:'send', title: $filter('translate')('global.literals.notSend')});
							countersField.templateOptions.options.push({id:'error', title: $filter('translate')('global.literals.notError')});
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'RegisterEntry' || x.tableName === 'NotificationThird'  || x.tableName === 'Remittance'").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										if(!f.state || f.state.startsWith("annexa.reg.output")){
											countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
											if(!dataDec || dataDec.length == 0){
												modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
											}
										}	
									});
								}
							}
						}else if(vm.boxType === 'TASKS'){
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_myTask"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_withinDeadline"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_outOfTime"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_pendingTasks"] = true;
								if(vm.twoColumns){
									modalSettings.annexaFormly.model.modal_body["counters_createdTask"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_withinDeadline2"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_outOfTime2"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_pendingTasks2"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_finalizedTasks"] = true;
								}
							}
							countersField.templateOptions.options.push({id:'myTask', title: $filter('translate')('global.task.myTask')});
							countersField.templateOptions.options.push({id:'withinDeadline', title: $filter('translate')('global.task.myTask')+" - "+$filter('translate')('global.literals.withinDeadline')});
							countersField.templateOptions.options.push({id:'outOfTime', title: $filter('translate')('global.task.myTask')+" - "+$filter('translate')('global.literals.outOfTime')});
							countersField.templateOptions.options.push({id:'pendingTasks', title: $filter('translate')('global.task.myTask')+" - "+$filter('translate')('global.task.pendingTasks')});
							if(vm.twoColumns){
								countersField.templateOptions.options.push({id:'createdTask', title: $filter('translate')('global.task.createdTask')});
								countersField.templateOptions.options.push({id:'withinDeadline2', title: $filter('translate')('global.task.createdTask')+" - "+$filter('translate')('global.literals.withinDeadline')});
								countersField.templateOptions.options.push({id:'outOfTime2', title: $filter('translate')('global.task.createdTask')+" - "+$filter('translate')('global.literals.outOfTime')});
								countersField.templateOptions.options.push({id:'pendingTasks2', title: $filter('translate')('global.task.createdTask')+" - "+$filter('translate')('global.task.pendingTasks')});
								countersField.templateOptions.options.push({id:'finalizedTasks', title: $filter('translate')('global.task.createdTask')+" - "+$filter('translate')('global.task.finalizedTasks')});
							}
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'TaskView' ").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
										if(!dataDec || dataDec.length == 0){
											modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
										}	
									});
								}
							}
						}else if(vm.boxType === 'ARCHIVE'){
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_finally"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_close"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_transfer"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_rejected"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_archive"] = true;
							}
							countersField.templateOptions.options.push({id:'finally', title: $filter('translate')('global.archive.finally.title')});
							countersField.templateOptions.options.push({id:'close', title: $filter('translate')('global.archive.close.title')});
							countersField.templateOptions.options.push({id:'transfer', title: $filter('translate')('global.archive.transfer.title')});
							countersField.templateOptions.options.push({id:'rejected', title: $filter('translate')('global.archive.rejected.title')});
							countersField.templateOptions.options.push({id:'archive', title: $filter('translate')('global.archive.archive.title')});
							if(GlobalDataFactory.userFilters){
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'Dossier' ").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										if(!f.state || f.state.startsWith("annexa.archive")){
											countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
											if(!dataDec || dataDec.length == 0){
												modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
											}
										}	
									});
								}
							}
						}else if(vm.boxType === 'SEC'){
							if(!dataDec || dataDec.length == 0){
								modalSettings.annexaFormly.model.modal_body["counters_propPending"] = true;
								if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
						                $rootScope.app.configuration.validation_circuit_secretary.value) {
									modalSettings.annexaFormly.model.modal_body["counters_propReview"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_propValidate"] = true;
									modalSettings.annexaFormly.model.modal_body["counters_propAccept"] = true;
								}
								modalSettings.annexaFormly.model.modal_body["counters_secPending"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_secConvo"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_secIni"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_secFin"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_decPending"] = true;
								modalSettings.annexaFormly.model.modal_body["counters_decSend"] = true;
							}
							countersField.templateOptions.options.push({id:'propPending', title: $filter('translate')('global.literals.propPending')});
							if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
					                $rootScope.app.configuration.validation_circuit_secretary.value) {
								countersField.templateOptions.options.push({id:'propReview', title: $filter('translate')('global.literals.propReview')});
								countersField.templateOptions.options.push({id:'propValidate', title: $filter('translate')('global.literals.propValidate')});
								countersField.templateOptions.options.push({id:'propAccept', title: $filter('translate')('global.literals.propAccept')});
							}
							countersField.templateOptions.options.push({id:'secPending', title: $filter('translate')('global.literals.secPending')});
							countersField.templateOptions.options.push({id:'secConvo', title: $filter('translate')('global.literals.secConvo')});
							countersField.templateOptions.options.push({id:'secIni', title: $filter('translate')('global.literals.secIni')});
							countersField.templateOptions.options.push({id:'secFin', title: $filter('translate')('global.literals.secFin')});
							countersField.templateOptions.options.push({id:'decPending', title: $filter('translate')('global.literals.decPending')});
							countersField.templateOptions.options.push({id:'decSend', title: $filter('translate')('global.literals.decSend')});
							if(GlobalDataFactory.userFilters) {
								var userFilters = $linq(GlobalDataFactory.userFilters).where("x => x.tableName === 'Proposal' || x.tableName === 'Session' || x.tableName === 'Decree' ").toArray();
								if(userFilters && userFilters.length > 0){
									_.forEach(userFilters, function(f){
										countersField.templateOptions.options.push({id: 'filter_'+f.id , title: f.name});
										if(!dataDec || dataDec.length == 0){
											modalSettings.annexaFormly.model.modal_body["counters_filter_"+f.id] = false;
										}	
									});
								}
							}
						}
					}
					AnnexaFormlyFactory.showModal('modalExpressionEvaluatorAdd', modalSettings, saveModalSettings, false);
				}
			}
			
            this.$onInit = function () {
                if(vm.twoColumns) {
                    vm.columnOneCounters = $linq(vm.counters).where("x => x.column == 1").toArray();
                    vm.columnTwoCounters = $linq(vm.counters).where("x => x.column == 2").toArray();
                }
            }
        }],
        bindings: {
            headState: '@',
            headParameters: '=?',
            iconType: '@',
            icon: '@',
            titleToShow: '@',
            counters: '=',
            twoColumns: '=',
 			boxType:'@?'
        }
    })
    .component('annexaDashboardBoxContent', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-box-content.html',
        controller: [function() {
            var vm = this;
        }],
        bindings: {
            counters: '='
        }
    })
    .component('annexaDashboardCounter', {
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-counter.html',
        controller:[function() {
            var vm = this;
        }],
        bindings: {
            number: '@'
        }
    })
    .component('annexaDashboardSign',{
        templateUrl: './components/common/annexa-dashboard/annexa-dashboard-sign.html',
        controller: ['GlobalDataFactory', 'Language', '$http', 'HelperService', 'TableFilterFactory', 'RestService', '$rootScope', function(GlobalDataFactory, Language, $http, HelperService, TableFilterFactory, RestService, $rootScope) {
            var vm = this;

            vm.signModule = $linq(GlobalDataFactory.modules).firstOrDefault({},"x => x.url == 'annexa.sign.pending'");
            vm.signActionTypes = [];
 			vm.signFilters = [];
			vm.showUrgent = false;
            vm.languageColumn = Language.getActiveColumn();
			var signTypes = angular.copy(GlobalDataFactory.signActionTypes);
			var dataDec = [];
			if(GlobalDataFactory.userCountersConfiguration && GlobalDataFactory.userCountersConfiguration.length > 0){	
				dataDec = $linq(GlobalDataFactory.userCountersConfiguration).where("x => x.active && x.counterType == 'SIGN'").toArray();
			}
            _.forEach(signTypes, function(item) {
				if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == '"+item.id+"'"))){
					var dsTrobat = $linq(vm.signActionTypes).firstOrDefault(undefined, "x => x.id == "+item.id);
	            	if(!dsTrobat){
	                    vm.signActionTypes.push(item);
		                $http({
		                    method: 'GET',
		                    url: './api/signprocessactions/countPending/' + item.id
		                }).then(function(data) {
		                    item.num = data.data > 0 ? data.data : '';
		                }).catch(function (error) {
		                    console.error(error);
		                });

	            	}
				}
            });
			var getUri = function(signFilter){
				if(signFilter.filter && signFilter.filter.state && signFilter.filter.id){
					if(signFilter.filter.state === 'annexa.sign.finished'){
						return signFilter.filter.state+'({filterFinishedId: '+signFilter.filter.id+'})';
					}else{
						return signFilter.filter.state+'({filterId: '+signFilter.filter.id+'})';
					}				
				}else if(signFilter.filter.id){
					return 'annexa.sign.pending({filterId: '+signFilter.filter.id+'})';
				}else{
					return 'annexa.sign.pending';
				}
			}
			if(!dataDec || dataDec.length === 0 || (dataDec && dataDec.length > 0 && $linq(dataDec).firstOrDefault(undefined,"x => x.name && x.name == 'urgent'"))){
	           vm.showUrgent = true;
				$http({
	                method: 'GET',
	                url: './api/signprocessactions/countPending/urgent'
	            }).then(function(data) {
	                vm.numUrgents = data.data > 0 ? data.data : '';
	            }).catch(function (error) {
	                console.error(error);
	            });
			}
			if(dataDec && dataDec.length > 0){
				_.forEach(dataDec, function(item) {
					if(item.filter && item.filter.id && !item.filter.deleted){
						var dsTrobat = $linq(vm.signFilters).firstOrDefault(undefined, "x => x.id === "+item.id);
						var dsUsersTrobat = $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined, "x => x.id && x.id ==="+item.filter.id);
		            	if(!dsTrobat && dsUsersTrobat){
							item.uri = getUri(item);
			                vm.signFilters.push(item);
							if(item.filter.state === 'annexa.sign.finished'){
								var filter = [
					                { id: 'signactiontype', type: 'select', order: 0, label: 'global.literals.actions', dataType: 'LOCAL', data: GlobalDataFactory.signActionTypes, addAll:true, nameProperty:Language.getActiveColumn(), preFilter:HelperService.getPrefilter('signature','tableFinished','signactiontype',GlobalDataFactory.signActionTypes,'id'), negated:HelperService.getPrefilterNegated('signature','tableFinished','signactiontype')},
					                { id: 'documenttype', type: 'select', order: 1, label: 'global.literals.docType', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.documentTypes, Language.getActiveColumn()).sort(), addAll:true, nameProperty:Language.getActiveColumn(), preFilter:HelperService.getPrefilter('signature','tableFinished','documenttype',GlobalDataFactory.documentTypes,'id'), negated:HelperService.getPrefilterNegated('signature','tableFinished','documenttype')},
					                { id: 'title', type: 'text', order: 2, label: 'global.literals.title', preFilter:HelperService.getPrefilter('signature','tableFinished','title'), negated:HelperService.getPrefilterNegated('signature','tableFinished','title')},
					                { id: 'observations', type: 'text', order: 3, label: 'global.literals.observations', preFilter:HelperService.getPrefilter('signature','tableFinished','observations'), negated:HelperService.getPrefilterNegated('signature','tableFinished','observations')},
					                { id: 'dates', type: 'dateRange', order: 4, label: 'global.literals.actionDo', callAux: true, preFilter:HelperService.getPrefilter('signature','tableFinished','dates'), negated:HelperService.getPrefilterNegated('signature','tableFinished','dates')},
					                { id: 'user', type: 'text', order: 6, label: 'global.sign.pending.signers', callAux: true, preFilter:HelperService.getPrefilter('signature','tableFinished','user'), negated:HelperService.getPrefilterNegated('signature','tableFinished','user')},
									{ id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.dossierNumber', callAux: true, preFilter:HelperService.getPrefilter('signature','tableFinished','dossierNumber'), negated:HelperService.getPrefilterNegated('signature','tableFinished','dossierNumber')}
					            ];
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCallOrig = TableFilterFactory.getFilterCall(filter);
					                var filterCall = {};
					                filterCall.userMustSign ={id: $rootScope.LoggedUser.id};
					                if(filterCallOrig && filterCallOrig.signactiontype){
					                    filterCall.signActionType = filterCallOrig.signactiontype;
					                }
					                if(filterCallOrig && (filterCallOrig.documenttype || filterCallOrig.title)){
					                    filterCall.signProcess = {document:{}};
					                    if(filterCallOrig && filterCallOrig.documenttype) {
					                        filterCall.signProcess.document.type = filterCallOrig.documenttype;
					                    }
					                    if(filterCallOrig && filterCallOrig.title) {
					                        filterCall.signProcess.document.name = filterCallOrig.title;
					                    }
					                }
					                filterCall.comments = filterCallOrig.observations;
						            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.finished = true;
					                filterCallAux.pending = true;
									return RestService.countersFilterDashboard(item.id+'', 'sign', 'SignProcessAction', filterCall, filterCallAux)
		                                .then(function (data) {
		                                    item.num = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
		                                });
								}									
							}else{
								var filter = [
					                { id: 'signactiontype', type: 'select', order: 0, label: 'global.literals.actions', dataType: 'LOCAL', data: GlobalDataFactory.signActionTypes, addAll:true, nameProperty:Language.getActiveColumn(), preFilter:HelperService.getPrefilter('signature','tablePending','signactiontype', GlobalDataFactory.signActionTypes, 'id'), negated:HelperService.getPrefilterNegated('signature','tablePending','signactiontype')},
					                { id: 'documenttype', type: 'select', order: 1, label: 'global.literals.docType', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.documentTypes, Language.getActiveColumn()).sort(), addAll:true, nameProperty:Language.getActiveColumn(), preFilter:HelperService.getPrefilter('signature','tablePending','documenttype', GlobalDataFactory.documentTypes, 'id'), negated:HelperService.getPrefilterNegated('signature','tablePending','documenttype')},
					                { id: 'title', type: 'text', order: 2, label: 'global.literals.title', preFilter:HelperService.getPrefilter('signature','tablePending','title'), negated:HelperService.getPrefilterNegated('signature','tablePending','title')},
					                { id: 'observations', type: 'text', order: 3, label: 'global.literals.observations', preFilter:HelperService.getPrefilter('signature','tablePending','observations'), negated:HelperService.getPrefilterNegated('signature','tablePending','observations')},
					                { id: 'dates', type: 'dateRange', order: 4, label: 'global.literals.actionDo', callAux: true, preFilter:HelperService.getPrefilter('signature','tablePending','dates'), negated:HelperService.getPrefilterNegated('signature','tablePending','dates')},
					                { id: 'user', type: 'text', order: 6, label: 'global.sign.pending.signers', callAux: true, preFilter:HelperService.getPrefilter('signature','tablePending','user'), negated:HelperService.getPrefilterNegated('signature','tablePending','user')},
									{ id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.dossierNumber', callAux: true, preFilter:HelperService.getPrefilter('signature','tablePending','dossierNumber'), negated:HelperService.getPrefilterNegated('signature','tablePending','dossierNumber')}
					            ]
								if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
									HelperService.getFilterByFilterId(filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+item.filter.id));
									var filterCallOrig = TableFilterFactory.getFilterCall(filter);
									var filterCall = {};
									filterCall.userMustSign = {id: $rootScope.LoggedUser.id};
				            		if(filterCallOrig && filterCallOrig.signactiontype){
					                    filterCall.signActionType = filterCallOrig.signactiontype;
					                }
									if(filterCallOrig && (filterCallOrig.documenttype || filterCallOrig.title)){
					                    filterCall.signProcess = {document:{}};
					                    if(filterCallOrig && filterCallOrig.documenttype) {
					                        filterCall.signProcess.document.type = filterCallOrig.documenttype;
					                    }
					                    if(filterCallOrig && filterCallOrig.title) {
					                        filterCall.signProcess.document.name = filterCallOrig.title;
					                    }
					                }
					                filterCall.comments = filterCallOrig.observations;
						            var filterCallAux = TableFilterFactory.getFilterCallAux(filter);
									filterCallAux.finished = false;
					                filterCallAux.pending = false;
									return RestService.countersFilterDashboard(item.id+'', 'sign', 'SignProcessAction', filterCall, filterCallAux)
		                                .then(function (data) {
					            				item.num = ((data.data && data.data.hasOwnProperty(item.id+"") && data.data[item.id] > 0 )? data.data[item.id] : '');
		                                });
								}				
							}
						}		
					}
	            })
			}
        }]
    });