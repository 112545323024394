/**
 * Created by osirvent on 09/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaRepodocVersions', {
        transclude: true,
        templateUrl: './components/common/annexa-repodoc-versions/annexa-repodoc-versions.html',
        controller: ['$http', 'DialogsFactory', 'DccumentsFactory', '$rootScope', '$state', function ($http, DialogsFactory, DccumentsFactory, $rootScope, $state) {
            var vm = this;
            
            vm.downloadVersion = function(version){
                if(vm.model && vm.model.documentId && version && version.versionId) {
                    $http.get("./api/repodocs/version/"+vm.model.documentId+"?version="+version.versionId, {responseType: 'arraybuffer'})
                        .success(function (data, status, headers) {
                            var retDownload = {
                                file: undefined,
                                fileName: undefined,
                                linkElement: undefined,
                                clickEvent: undefined
                            }
                            headers = headers();
                            var contentType = headers['content-type'];
                            retDownload.file = new Blob([data], {type: contentType});
                            retDownload.fileName = version.documentName;
                            try {
                                if (window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                                } else {
                                    retDownload.linkElement = document.createElement('a');
                                    var url = window.URL.createObjectURL(retDownload.file);
                                    retDownload.linkElement.setAttribute('href', url);
                                    retDownload.linkElement.setAttribute("download", retDownload.fileName);
                                    retDownload.clickEvent = new MouseEvent("click", {
                                        "view": window,
                                        "bubbles": true,
                                        "cancelable": false
                                    });
                                    retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                                }
                            } catch (e) {
                            }
                        }).error(function (error) {
                    });
                }
            }
            
            vm.revertToVersion = function(version){
            	if(vm.model && vm.model.documentId && version && version.versionId) {
	            	DialogsFactory.confirm('global.documents.new.revert', 'global.documents.new.revertConfirm')
	                .then(function (data) {
	                	DccumentsFactory.revertVersion(vm.model.documentId, version.versionId).then(function (data) {
	                		if(data){
	                			var newData = JSOG.decode(data);
                				try{
                        			version.modifiedAtAux = new Date(newData.modifiedAt);
                        		}catch(e){
                        			version.modifiedAtAux = undefined;
        						}
	                			vm.model.versions.unshift(newData);
	                			if(vm.model.modal){
	                				vm.model.modal.close();
	                			}
	                			$state.reload();
	                		}else{
	                			DialogsFactory.error($filter('translate')('global.documents.new.revertError'));
	                		}
	    	            }).catch(function (error) {
	    	            	DialogsFactory.error($filter('translate')('global.documents.new.revertError'));
	    	            });
	               }).catch(function (data) {
	    	               //Empty
	    	       });
            	}
            }
            
            this.$onInit = function () {
            	if(!vm.model){
            		vm.model = {};
            	}
                if(vm.model && !vm.model.versions){
                	vm.model.versions = [];
                }
                if(vm.model.versions && vm.model.versions.length > 0){
                	_.forEach(vm.model.versions, function(version){
                		try{
                			version.modifiedAtAux = new Date(version.modifiedAt);
                		}catch(e){
                			version.modifiedAtAux = undefined;
						}
                	});
                }
            }
        }],
        bindings: {
        	model: '='
        }
    })
    
        