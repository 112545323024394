angular
    .module('annexaApp')
    .component('annexaViewDossierTransaction', {
        templateUrl: './components/common/annexa-view-dossier-transaction/annexa-view-dossier-transaction.html',
        controller: ['$filter', '$rootScope','AnnexaPermissionsFactory', '$http', '$state', 'DialogsFactory', function($filter, $rootScope, AnnexaPermissionsFactory, $http, $state, DialogsFactory) {
            var vm = this;
            vm.dossiers = [];

			this.$onInit = function() {
				if(!vm.showFilter){
					vm.showFilter = false;
				}
				if(vm.idDocument){
					$http({url: './api/tram/decreeDossier/'+vm.idDocument,method: 'GET'}).then(function(data) {
						if(vm.dossierTransactions && vm.dossierTransactions.length > 0){
			                vm.dossiers = $linq(vm.dossierTransactions).distinct("(x, y) => x.dossier.id == y.dossier.id").select("x => x.dossier").toArray();
			            }
						if(data && data.data){
							var decodedData = JSOG.decode(data.data);
							if(decodedData && decodedData.length > 0){
								_.forEach(decodedData, function(dos){
									var exist = $linq(vm.dossiers).firstOrDefault(undefined, "x => x.id == "+dos.id);
									if(!exist){
										vm.dossiers.push(dos);
									}
								});
							}
						}
	                }).catch(function (error) {
						if(vm.dossierTransactions && vm.dossierTransactions.length > 0){
			                vm.dossiers = $linq(vm.dossierTransactions).distinct("(x, y) => x.dossier.id == y.dossier.id").select("x => x.dossier").toArray();
			            }        	
	                });
				}else{
					if(vm.dossierTransactions && vm.dossierTransactions.length > 0){
		                vm.dossiers = $linq(vm.dossierTransactions).distinct("(x, y) => x.dossier.id == y.dossier.id").select("x => x.dossier").toArray();
		            }				
				}
            }
            
            vm.haveProfileDossier = function(dossier){
                var haveViewProfile = false;
                var haveTramitationProfile = false;
                var haveProcessProfile = false;
                var isResponsibleUser = false;
                var haveSeeAllDossierPermission = false;
                var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                
                if(AnnexaPermissionsFactory.havePermission(['view_dossier', 'see_all_dossier']) || AnnexaPermissionsFactory.havePermission('process_dossier')) {
                	$http({url: './api/tram/permission_dossier/'+dossier.id,method: 'GET'}).then(function(data) {
                        var decodedData = JSOG.decode(data.data);
                        var dossierViewProfiles = ((decodedData.viewProfiles)?decodedData.viewProfiles: []);
                        var dossierTramitationProfiles = ((decodedData.tramitationProfiles)?decodedData.tramitationProfiles: []);
                        var dossierProcessProfiles = ((decodedData.processProfiles)?decodedData.processProfiles:[]);
                        var responsible = decodedData.responsible;
                        var bigDossier = decodedData.bigDossier;
                        var canAccessEMGDE = true;
                        
                        var intersect = $linq(userProfiles).intersect(dossierViewProfiles, "(x,y) => x == y").toArray();
                        haveViewProfile = intersect.length > 0;
                        
                        var intersect = $linq(userProfiles).intersect(dossierTramitationProfiles, "(x,y) => x == y").toArray();
                        haveTramitationProfile = intersect.length > 0;

                        var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
                        haveProcessProfile = intersect.length > 0;

                        if(responsible && responsible == $rootScope.LoggedUser.id) {
                        	isResponsibleUser = true;
                        }
                        
                        if(AnnexaPermissionsFactory.havePermission('see_all_dossier')) {
                        	haveSeeAllDossierPermission = true;
                        }
                        
                        if(decodedData.hasOwnProperty('restrictedAccess')) {
                        	canAccessEMGDE = !decodedData.restrictedAccess;
                        }
                        
                        var openDossierFunction = function(){
                        	if(AnnexaPermissionsFactory.havePermission('process_dossier') && (isResponsibleUser || haveProcessProfile) && canAccessEMGDE) {
    	                		var dt = $linq(vm.dossierTransactions).firstOrDefault(undefined,"x => x.actual && x.dossier.id == "+dossier.id);
    	                        window.open($state.href('annexa.tram.pending.viewdossier', {dossier: dossier.id, dossierTransaction: ((dt && dt.id)?dt.id:-1)}), '_blank');
    	                    }else if(AnnexaPermissionsFactory.havePermission(['view_dossier', 'see_all_dossier']) && (haveViewProfile || haveTramitationProfile || haveSeeAllDossierPermission) && canAccessEMGDE){
    	                        window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossier.id, dossierTransaction: -1 }), '_blank');
    	                    }else if((haveViewProfile || isResponsibleUser || haveProcessProfile || haveTramitationProfile) && canAccessEMGDE){
    	                        window.open($state.href('annexa.tram.view.viewdossier', { dossier: dossier.id, dossierTransaction: -1 }), '_blank');
    	                    }else{
                            	DialogsFactory.notify($filter('translate')('global.literals.notPermission'));
                            }
                        }
                        if(dossier && dossier.dossierStatus && (dossier.dossierStatus == 'ARCHIVED' || dossier.dossierStatus == 'REJECTED' || dossier.dossierStatus == 'TRANSFER')){
                        	DialogsFactory.notify($filter('translate')('global.literals.notPermission'));
                    	}else{
	                    	if((haveViewProfile || isResponsibleUser || haveProcessProfile || haveSeeAllDossierPermission || haveTramitationProfile) && canAccessEMGDE){
	                        	if(bigDossier){
	                        		DialogsFactory.confirm('global.literals.bigDossierMessage', 'global.literals.bigDossierMessageConfirm').then(function (data) {
	                        			openDossierFunction();
		            	           }).catch(function (data) {});
	                        	}else{
	                        		openDossierFunction();
	                        	}
	                        }else{
	                        	DialogsFactory.notify($filter('translate')('global.literals.notPermission'));
	                        }
                    	}
                    }).catch(function (error) {
                        
                    });
                }else{
                	DialogsFactory.notify($filter('translate')('global.literals.notPermission'));
                }
            }

			vm.filterFor = function(dossier){
				if(dossier && dossier.dossierNumber){
					$rootScope.$broadcast('filterSignBydossierNumber', { dossierNumber: dossier.dossierNumber });
				}
			}
 
        }],
        bindings: {
            dossierTransactions: '=',
            startText: '@?',
            endText: '@?',
            class:'@?',
			idDocument: '@?',
			showFilter: '@?'
        }
    })